import { ReactComponent as ZenaLogo } from '../img/zena-logo.svg'
import NavBar from './NavBar'
import '../css/Layout.css'
import useResponsive from '../responsive'

export default function LayoutScreen({heroSrc, children, onBack, showProfile, style={}, white=false}) {
  const isDesktop = !useResponsive().mobile

  if (white) {
    if (isDesktop) {
      style.layoutMain = {
        background: "white",
        ...style.layoutMain,
      }
    } else {
      style.navbar = {
        header: {
          background: "white",
          ...style.navbar?.header,
        },
        ...style.navbar,
      }
      
      style.root = {
        background: "white",
        ...style.root,
      }
    }
  }

  const navbar = <NavBar variant="secondary" onBack={onBack} isAlt={isDesktop && !!heroSrc} showProfile={showProfile} style={style.navbar}/>

  if (isDesktop) {
    return <DesktopScreen hero={heroSrc} content={children} navbar={navbar} style={style} />
  }
  return <MobileScreen hero={heroSrc} content={children} navbar={navbar} style={style} />
}

function DesktopScreen({hero, content, navbar, style={}}) {
  const zena = <ZenaLogo style={{width: "100%", height: "auto"}}/>

  return (
    <div className={`layout-desktop ${hero ? "has-hero" : "no-hero"}`} style={style.root}>
      {navbar}
      <div className="layout-main" style={style.layoutMain}>
        {
          hero &&
            <div className="layout-hero-bar">
              <div>
                <div className="layout-hero-container">
                  <img src={hero} />
                </div>
                <div style={{display: "flex", justifyContent: "center", marginTop: "16px"}}>
                  <div style={{width: "100px"}}>
                    {zena}
                  </div>
                </div>
              </div>
            </div>
        }
        {
          !hero &&
            <div className="layout-zena-bar">
              <div className="layout-zena-bar-logo">
                {zena}
              </div>
            </div>
        }
        <div style={{flex: 1}}>
          <div className="layout-content">
            {content}
          </div>
        </div>
      </div>
    </div>
  )
}

function MobileScreen({hero, content, navbar, style={}}) {
  return (
    <div className={`layout-mobile ${hero ? "has-hero" : "no-hero"}`} style={style.root}>
      {navbar}
      <div className="layout-main" style={style.layoutMain}>
        <div style={{width: "clamp(357px, 100%, 500px)", ...style.layoutMainMobileInner}}>
          <div style={{marginBottom: "16px"}}>
            {hero && <img className="hero-image" src={hero} style={{width: "100%"}} />}
          </div>
          {content}
        </div>
      </div>
    </div>
  )
}
