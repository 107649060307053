import welcome from './welcome.png'
import success from './success.png'
import caution from './caution.png'
import bloodPressure from './blood-pressure.png'
import stop from './stop.png'
import zenaBlisterPack from './zena-blister-pack.png'
import selectPackImg from './select-pack-img.png'
import sampleQR from './sample-qr.png'
import visa from './card-visa.png'
import amex from './card-amex.png'
import mastercard from './card-mastercard.png'
import discover from './card-discover.png'
import cautionSign from './caution-sign.png'

const images = {
    'welcome': welcome,
    'success': success,
    'caution': caution,
    'blood-pressure': bloodPressure,
    'stop': stop,
    'zena-blister-pack': zenaBlisterPack,
    'select-pack-img': selectPackImg,
    'sample-qr': sampleQR,
    'card-visa': visa,
    'card-amex': amex,
    'card-mastercard': mastercard,
    'card-discover': discover,
    'caution-sign': cautionSign
}

export default images
