import { useState, useEffect, useContext, Component } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardElement, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';

import { checkoutClient } from '../client'

const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

function withParams(Inner) {
  return (props) => {
    const params = useParams()

    return (
      <Inner {...props} params={params}/>
    )
  }
}

// {
//   "setupIntent": {
//     "id": "seti_1MEghh2eZvKYlo2CLioxm7u2",
//     "object": "setup_intent",
//     "cancellation_reason": null,
//     "client_secret": "seti_1MEghh2eZvKYlo2CLioxm7u2_secret_Mydz5LBCs9u1YCPmRfmNwadiOznZ6WK",
//     "created": 1670969521,
//     "description": null,
//     "last_setup_error": null,
//     "livemode": false,
//     "next_action": null,
//     "payment_method": "pm_1MEghp2eZvKYlo2CSIbDYbtF",
//     "payment_method_types": [
//       "card"
//     ],
//     "status": "succeeded",
//     "usage": "off_session"
//   }
// }
function CheckoutForm({onSetup}) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault()

    const { setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: window.location.origin + window.location.pathname,
      },
    })

    onSetup(setupIntent)
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement/>
      <button type="submit">ok</button>
    </form>
  )
}

function AddCard({onSetup}) {
  const [ setupIntent, setSetupIntent ] = useState({})

  useEffect(() => {
    console.log("AddCard")
    checkoutClient.createSetupIntent()
      .then(setSetupIntent)
  }, [])

  const { clientSecret } = setupIntent

  return (
    <div style={{maxWidth: "600px"}}>
      { clientSecret && (
        <Elements stripe={stripePromise} options={{clientSecret}}>
          <CheckoutForm onSetup={onSetup}/>
        </Elements>
      )}
    </div>
  )
}

const styles = {
  primary: "rgb(83, 66, 153)",
}

export function CardView({header, Edit, View, onSubmit}) {
  const [editing, setEditing] = useState(false)

  const cardStyle = {
    border: "1px solid lightgrey",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  }

  const headerStyle = {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
  }

  const handleSubmit = (x) => {
    setEditing(false)
    onSubmit(x)
  }

  console.log("CardView", {editing})

  return (
    <div style={cardStyle}>
      <div style={headerStyle}>
        <div style={{flex: "1"}}>
          <h2 style={{margin: 0}} className="secondary" tabIndex="1">{header}</h2>
        </div>
        <div style={{flex: "0", color: styles.primary}}>
          <a href="#" onClick={(e) => {
            e.preventDefault()
            console.log("edit clicked")
            setEditing(true)
          }}>Edit</a>
        </div>
      </div>
      <div>
        {editing ? <Edit onSubmit={handleSubmit}/> : <View/>}
      </div>
    </div>
  )
}

export default function CheckoutPage() {
  const params = useParams()
  const [ paymentMethod, setPaymentMethod ] = useState({})
  const [ selectedCard, setSelectedCard ] = useState({})

  console.log({selectedCard, paymentMethod})

  useEffect(() => {
    if (paymentMethod.payment_method) {
      checkoutClient.getPaymentMethod({id: paymentMethod.payment_method})
        .then(pm => setSelectedCard(pm))
    }
  }, [paymentMethod])

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      maxWidth: "500px",
      margin: "20px",
    }}>
    <CardView header="Cart"
      View={() => (<div></div>)}
      Edit={() => (<div></div>)}
    >

    </CardView>
    <CardView
      header="Payment"
      onSubmit={setPaymentMethod}
      View={() => (
        <div>
          {selectedCard.last4 ? `${selectedCard.brand} ${selectedCard.last4}` : ""}
        </div>
      )}
      Edit={({onSubmit}) => (
        <AddCard onSetup={onSubmit}/>
      )}

    >

    </CardView>


    </div>
  )
}
