import { useSearchParams, useParams, searchParams } from "react-router-dom";
import { client } from "../client"


import "../css/Outcome.css";
import success from "../img/success.png";
import caution from "../img/caution.png";
import arrowBack from "../icn/arrow-back.svg";
import profile from "../icn/profile.svg";
import { useState, useEffect, createElement } from 'react';
import { AssessmentButton, RadioSelectList, createComponent } from '../components/AssessmentComponents';
import { BcCheckbox } from '../components/ConfirmModals';
import ModalContext from '../modal'
import { useContext } from 'react'
import { useAudit } from '../audit'
import { useMetrics } from "../metrics"
import { BloodPressureInfo } from '../components/MoreInfo'
import history from '../history'
import { BaseScreen } from "../components/Assessment"
import { BpNumbersRecentModal } from "../components/ConfirmModals"
import router from '../history'
import { BpModal } from "../components/ConfirmModals"
import LayoutScreen from "../components/Layout"
import images from '../img/index'
import { usePrompt, skipPrompt } from '../prompt'
import { CheckoutPrompt } from './CheckoutTmp'

import RouterLink from "../components/RouterLink";
import useResponsive from "../responsive"
import { useStateWithSessionStorage } from "../hooks";

function join(arr) {
  if (arr.length == 0) {
    return ''
  }

  if (arr.length == 1) {
    return arr[0]
  }
  return arr.slice(0, arr.length - 1).join(', ') + ` and ${arr[arr.length - 1]}`
}

function getMedications(answers) {
  let medications = [
    ...(answers.hepatitisMeds || []),
    ...(answers.thyroidMeds || []),
    ...(answers.epilepsyMeds || []),
    ...(answers.hivMeds || []),
    ...(answers.cholesterolMeds || []),
    ...(answers.antibacterialMeds || []),
    ...(answers.otherMeds || [])
  ]
  return medications
} 

function formatMedications(medications) {
  const formatted = []

  medications.forEach(m => {
    m = m.replaceAll("_", " ")

    // https://ideaevolver.atlassian.net/browse/CADENCE-503
    if (m === "st johns wort") {
      m = "St John's Wort"
    }

    formatted.push(m)
  })

  return formatted
}

export function AdbuList({answers}) {
  const flags = {
    highBMI: answers.bmi >= 35,
    otherCancer: (answers.cancer || []).includes('cancer_other'), // cancer is conditional so default if undefined
    highCholesterol: answers.conditions.includes('highCholesterol'),
    gallbladderDisease: answers.gallbladderDisease == 'yes',
    clinicallyDiagnosedDepression: answers.diagnosedDepression == 'yes'
  }

  const conditions = []
  if (flags.otherCancer) {
    conditions.push("other cancer")
  }

  if (flags.highCholesterol) {
    conditions.push("high cholesterol")
  }

  if (flags.gallbladderDisease) {
    conditions.push("gallbladder disease")
  }

  if (flags.clinicallyDiagnosedDepression) {
    conditions.push("clinically diagnosed depression")
  }

  const medications = formatMedications(getMedications(answers))
  
  return (
    <div>
      <ul>
        {flags.highBMI && (
            <li>you have a BMI greater than 35</li>
          )
        }
        {
          conditions.length > 0 && (
            <li>you have or have had {join(conditions)}</li>
          )
        }
        {
          medications.length > 0 && (
            <li>you are taking {join(medications)}, which may interact with Zena, so it is important to talk to a doctor before using them together</li>
          )
        }
      </ul>
    </div>
  )
}

function AdbuScreenNoConfirmationModalContent({onBack, onConfirm}) {
  const [checked, setChecked ] = useState(false)
  const onEvent = useAudit({type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_DISPLAYED"})

  return (
    <div>
       <p style={{margin: "16px 0 24px 0"}}>By clicking the checkmark box, I confirm that I will need to <b>ask a doctor if Zena</b> is safe for me based on my information.</p>
       <BcCheckbox
          checked={checked}
          onCheck={() => setChecked(v => !v)}
          title={"I have read and understand"}
          variant="primary"
          style={{
            checkbox: {alignItems: "flex-start", minHeight: "initial"},
            title: {fontWeight: "normal"}
          }} />
        <button
          className="btn primary outline thin"
          style={{margin: "24px 0 16px 0"}}
          onClick={async () => {
            await onEvent({type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_GO_BACK"})
            onBack()
          }}>Go Back</button>
        <button
          className="btn primary"
          disabled={!checked}
          onClick={async () => {
            await onEvent({type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_CONFIRMED"})
            onConfirm()
        }}>Confirm</button>
    </div>
  )
}

function AdbuScreenYesConfirmationModalContent({onConfirm}) {
  const [checked, setChecked ] = useState(false)
  const onEvent = useAudit({type: "OUTCOME_DOCTOR_APPROVED_CONFIRM_MODAL_DISPLAYED"})

  return (
    <div>
       <p style={{margin: "16px 0 24px 0"}}>By clicking the checkmark, I confirm that <b>I have talked to a doctor who confirmed</b> that Zena is safe for me based on the information I provided.</p>
       <BcCheckbox
          checked={checked}
          onCheck={() => setChecked(v => !v)}
          title={"I have confirmed with a doctor that Zena is safe for me."}
          variant="primary"
          style={{
            checkbox: {alignItems: "flex-start", minHeight: "initial"},
            title: {fontWeight: "normal"}
          }} />
        <button
          className="btn primary"
          disabled={!checked}
          style={{margin: "24px 0 16px 0"}}
          onClick={() => {
            onEvent({type: "OUTCOME_DOCTOR_APPROVED_CONFIRM_MODAL_CONFIRMED"})
            onConfirm()
          }}>Confirm</button>
    </div>
  )
}

export function AdbuScreen({answers, onConfirmYes, onConfirmNo, needsBloodPressure}) {
  const setModal = useContext(ModalContext)
  const onEvent = useAudit({type: "OUTCOME_ADBU_DISPLAYED"})

  return (
    <div className="outcome-content">
      <h1 className="secondary">
        {needsBloodPressure && (
          <>
            <span>You need your recent blood pressure numbers.</span><br />
            <br />
          </>
        )}
        <span>Ask a doctor if Zena is safe for you.</span>
      </h1>
      <div className="content">
        <p>Based on the information you provided you will need to talk to a doctor because:</p>
        <AdbuList answers={answers} />
        <p>Your doctor may decide that it is OK for you to use Zena or may prescribe an alternative birth control product.</p>
        <p>Have you talked to a doctor and they said Zena is safe for you?</p>
        <div style={{display: "flex", justifyContent: "center", marginTop: "32px", gap: "16px"}}>
          <button className="btn primary outline" onClick={() => {
            onEvent({type: "OUTCOME_ADBU_START_SCREEN_NO_CLICKED"})
            setModal(() => (
              <AdbuScreenNoConfirmationModalContent
                onBack={() => {
                  setModal()
                }}
                onConfirm={() => {
                  onConfirmNo(() => setModal())
                }}/>
            ))
          }}>No</button>
          <button className="btn primary" onClick={() => {
            onEvent({type: "OUTCOME_ADBU_START_SCREEN_YES_CLICKED"})
            setModal(() => (
              <AdbuScreenYesConfirmationModalContent
                onConfirm={() => {
                  onConfirmYes(() => setModal())
                }}/>
            ))
          }}>Yes</button>
        </div>
      </div>
    </div>
  )
}

function Purchase({assessmentId, referenceNumberComponent, recordType}) {
  useAudit({type: "OUTCOME_SAFE_TO_USE_DISPLAYED"})
  const recordMetric = useMetrics()

  const isClinician = recordType === "clinician"
  return (
    <LayoutScreen
      heroSrc={images.success}
      showProfile={true}>
        <div className="outcome-content" style={{maxWidth: "500px"}}>
          <h1 className="secondary">You’re ready to purchase Zena.</h1>
          <div className="content">
            <p>
              Are you buying in-store or online?
            </p>
            <div style={{display: "flex", flexDirection: "column", gap: "24px", marginTop: "60px", alignItems: "center"}}>
              <button disabled={isClinician} className="btn primary" onClick={() => {
                skipPrompt()
                recordMetric({purchaseOption: "instore"})
                history.push(`/qr/${assessmentId}` + window.location.search)
              }}>Buy in-store now</button>
              <button disabled={isClinician} className="btn primary outline" onClick={() => {
                skipPrompt()
                recordMetric({purchaseOption: "online"})
                history.push(`/checkout/${assessmentId}` + window.location.search)
              }}>Order online</button>
            </div>
          </div>
        </div>
      { createElement(referenceNumberComponent, {}, []) }
    </LayoutScreen>
  )
}

function BloodPressureStartScreen({onClick}) {
  useAudit({type: "OUTCOME_BLOODPRESSURE_DISPLAYED"})

  return (
    <div className="outcome-content">
      <h1 className="secondary">Based on your answers, Zena could be right for you to use!</h1>
      <div className="content">
        <p>You need to enter a current (past 3 months) blood pressure.</p>
        <p>There are easy ways to get your BP numbers measured.</p>
        <p style={{marginBottom: "32px"}}>When you have your blood pressure numbers you can return to the survey to enter them and confirm if Zena is safe for you.</p>
        <BloodPressureInfo style={{width: "100%"}} />
        <div style={{marginTop: 36, display: "flex", justifyContent: "center"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <span style={{marginBottom: "16px"}}>Ready to enter your blood pressure?</span>
            <AssessmentButton variant="primary outline thin" onClick={() => onClick()} style={{fontSize: "16px"}}>
              Enter blood pressure numbers
            </AssessmentButton>
          </div>
        </div>
      </div>
    </div>
  )
}

function DDICaution({medications}) {
  const style = {
    root: {
      padding: "25px 16px 16px 16px",
      background: "rgba(250, 216, 38, 1)",
      borderRadius: "12px",
      fontWeight: "bold",
      display: "flex",
      gap: "8px",
      alignItems: "start"
    },
    img: {
      width: "58px", height: "auto"
    }
  }

  return (
    <div style={style.root}>
      <img src={images["caution-sign"]} alt="" style={style.img}/>
      Use back up birth control while using: {join(formatMedications(medications))}
    </div>
  )
}

function AdbuDDIScreen({medications, onBack, onSubmit}) {
  useAudit({type: "OUTCOME_DDI_SCREEN_DISPLAYED"})

  return (
    <BaseScreen
      title=""
      onSubmit={() => {
        onSubmit({ddiConfirm: "yes"})
      }}
      showBackButton={true}
      onBack={onBack}>
        <DDICaution medications={medications} />
        <p style={{marginTop: "28px"}}>
          You will need to use back up birth control when taking Zena because the 
          other drugs you are using may cause Zena to be less effective. 
          If this concerns you, you should talk to your doctor. 
        </p>
    </BaseScreen>
  )
}

const bloodPressureMainContentSpec = {
  "type": "Column",
  "children": [
    {
      "type": "Paragraph",
      "text": "Blood pressure is made up of two numbers. The top number (systolic) should always be higher than the bottom number (diastolic)."
    },
    {
      "type": "MoreInfoIconPlusComponent",
      "children": [
        {
          "type": "Paragraph",
          "text": "How to read the blood pressure monitor:"
        },
        {
          "type": "Image",
          "src": "blood-pressure"
        }
      ]
    },
    {
      "type": "Row",
      "justify": "center",
      "children": [
        {
          "type": "Row",
          "style": {
            "width": "min(100%, 300px)",
            "justifyContent": "flex-end"
          },
          "children": [
            {
              "type": "Column",
              "style": {
                "flex": "0 0 auto",
                "gap": "25px",
                "padding": "19px",
                "backgroundColor": "rgba(245, 245, 245, 1)",
                "border": "1px solid #9E9E9E",
                "borderRadius": "5px",
                "width": "153px"
              },
              "children": [
                {
                  "id": "systolic",
                  "type": "NumericInput",
                  "question": "Systolic Pressure<br>(top number)",
                  "validation": [
                    {
                      "cond": "answer.length === 0 || answer[0] === '0' || Number.isNaN(Number(answer)) || Number(answer) <= 0",
                      "err": "Please enter valid number"
                    }
                  ],
                  "variant": "primary",
                  "maxLength": 3,
                  "nextFocus": "diastolic",
                  "style": {
                    "base": {
                      "position": "relative"
                    },
                    "input": {
                      "textAlign": "center"
                    },
                    "label": {
                      "textAlign": "center",
                      "position": "absolute",
                      "top": "6px",
                      "left": "calc(-19px - 150px)"
                    }
                  }
                },
                {
                  "type": "Spacer",
                  "style": {
                    "height": "1px"
                  }
                },
                {
                  "id": "diastolic",
                  "type": "NumericInput",
                  "question": "Diastolic Pressure<br>(bottom number)",
                  "validation": [
                    {
                      "cond": "answer.length === 0 || answer[0] === '0' || Number.isNaN(Number(answer)) || Number(answer) <= 0",
                      "err": "Please enter valid number"
                    }
                  ],
                  "variant": "primary",
                  "maxLength": 3,
                  "style": {
                    "base": {
                      "position": "relative"
                    },
                    "input": {
                      "textAlign": "center"
                    },
                    "label": {
                      "textAlign": "center",
                      "position": "absolute",
                      "top": "6px",
                      "left": "calc(-19px - 150px)"
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

function BloodPressureInputScreen({setModal, onBack, onSubmit}) {
  const [systolic, setSystolic] = useState('')
  const [diastolic, setDiastolic] = useState('')
  const [shouldShowErrs, setShouldShowErrs] = useState(false)
  const _onEvent = useAudit({type: "OUTCOME_BLOODPRESSURE_INPUT_DISPLAYED"})
 
  const checkIsInvalid = answer => answer.length === 0 || answer[0] === '0' || Number.isNaN(Number(answer)) || Number(answer) <= 0

  const screenErrs = {}
  if (checkIsInvalid(systolic)) {
    screenErrs.systolic = "Please enter valid number"
  }
  if (checkIsInvalid(diastolic)) {
    screenErrs.diastolic = "Please enter valid number"
  }

  const onEvent = (e) => {
    const _e = {...e}
    
    // these answers will actually be saved as returningSystolic etc, so modify the event to reflect that
    if (e.type === "UPDATED_ANSWER") {
      switch (e.componentId) {
        case "systolic":
          _e.componentId = "returningSystolic"
          break
        case "diastolic":
          _e.componentId = "returningDiastolic"
          break
        case "knowBpNumbers":
          _e.componentId = "returningKnowBpNumbers"
          break
        case "bpNumbersRecent":
          _e.componentId = "returningBpNumbersRecent"
          break
      }
    }

    return _onEvent(_e)
  }

  const showRecentModal = () => {
    onEvent({type: "OUTCOME_BLOODPRESSURE_RECENT_MODAL_DISPLAYED"})
    setModal(() => (
      <BpNumbersRecentModal
        id="bpNumbersRecent"
        onEvent={onEvent}
        onConfirm={({ bpNumbersRecent }) => {
          onEvent({type: "UPDATED_ANSWER", componentId: "returningKnowBpNumbers", newValue: "yes"})

          setModal()
          onSubmit({
            systolic,
            diastolic,
            bpNumbersRecent,
            knowBpNumbers: "yes",
          })
        }} />
    ))
  }

  const showWarningModal = () => {
    onEvent({type: "OUTCOME_BLOODPRESSURE_WARNING_MODAL_DISPLAYED"})
    setModal(() => (
      <BpModal
        systolic={systolic}
        diastolic={diastolic}
        onConfirm={() => {
          setModal()
          showRecentModal()
        }}
        onClose={() => {
          setModal()
        }} />
    ))
  }

  // HACK
  const {mobile} = useResponsive()

  return (
    <BaseScreen
      title="What is your most recent blood pressure?"
      onSubmit={() => {
        if (Object.keys(screenErrs).length > 0) {
          setShouldShowErrs(true)
          return
        }

        if (Number(systolic) <= Number(diastolic)) {
          showWarningModal()
        } else {
          showRecentModal()
        }
      }}
      disableContinue={!systolic || !diastolic}
      showBackButton={true}
      backButtonText="I don't know"
      style={mobile ? {buttonContainer: {flexWrap: "wrap"}} : {}}
      onBack={onBack}>
        {
          createComponent(bloodPressureMainContentSpec, {
            answers: {systolic, diastolic},
            getValue: (qid) => {
              if (qid === "systolic") {
                return systolic
              } else if (qid === "diastolic") {
                return diastolic
              }
            },
            onEvent: (e) => {
              onEvent(e)
              if (e.type === "UPDATED_ANSWER") {
                if (e.componentId === "systolic") {
                  setSystolic(e.newValue)
                } else if (e.componentId === "diastolic") {
                  setDiastolic(e.newValue)
                }
              }
            },
            screenErrs,
            shouldShowErrs,
            hideErrs: () => {
              setShouldShowErrs(false)
            },
          })
        }
    </BaseScreen>
  )
}

function AdbuThankYouScreen({answers, needsBloodPressure}) {
  useAudit({type: "OUTCOME_THANK_YOU_DISPLAYED"})

  return (
    <div className="outcome-content">
      <h1 className="secondary">Thank you for visiting!</h1>
      <div className="content">
        {needsBloodPressure && <p>You will need to get your recent blood pressure numbers.</p>}
        <p>Based on the information you provided you will need to talk to a doctor because:</p>
        <AdbuList answers={answers} />
        <p>Your doctor may decide that it is OK for you to use Zena or may prescribe an alternative birth control product.</p>
        <p>If your doctor says it's OK to use Zena please come back and complete the survey.</p>
      </div>
    </div>
  )
}

function OutcomeFlow({ state = { outcome: "" }, onReturnEdit, referenceNumberComponent, returning }) {
  const { answers } = state
  const setModal = useContext(ModalContext)
  const adbu = answers.adbu
  const needsBloodPressure = !answers.systolic || !answers.diastolic || answers.knowBpNumbers !== "yes" || answers.bpNumbersRecent !== "yes"
  const { desktop } = useResponsive()

  const onEvent = useAudit()

  // depending on if they are returning or not we submit answers to different keys
  let keymap = {
    adbuScreenId: returning ? "returningADBUScreen" : "initialADBUScreen",
    ddiScreenId: returning ? "returningDDIScreen": "initialDDIScreen",
    bloodPressureScreenId: returning ? "returningBloodPressureScreen" : "finalBloodPressureScreen",

    askedDoctor: returning ? "returningAskedDoctor" : "initialAskedDoctor",
    willAskDoctor: returning ? "returningWillAskDoctor" : "initialWillAskDoctor",
    doctorAttestation: returning ? "returningDoctorAttestation" : "initialDoctorAttestation",
    ddiConfirm: returning ? "returningDDIConfirm" : "initialDDIConfirm",

    systolic: returning ? "returningSystolic" : "finalSystolic",
    diastolic: returning ? "returningDiastolic" : "finalDiastolic",
    knowBpNumbers: returning ? "returningKnowBpNumbers" : "finalKnowBpNumbers",
    bpNumbersRecent: returning ? "returningBpNumbersRecent" : "finalBpNumbersRecent",
  }
  
  const [currentScreen, setCurrentScreen] = useStateWithSessionStorage("OutcomeFlow_currentScreen", (() => {
    if (adbu) {
      return "adbu_start"
    }
    if (needsBloodPressure) {
      return "blood_pressure_start"
    }
    return ""
  })())
  
  const body = (() => {
    switch (currentScreen) {
      case "adbu_start":
        return (
          <AdbuScreen
            answers={answers}
            needsBloodPressure={needsBloodPressure}
            onConfirmNo={async (closeModal) => {
              // can send these events at the same time
              await Promise.all([
                onEvent({type: "UPDATED_ANSWER", componentId: keymap.askedDoctor, newValue: "no"}),
                onEvent({type: "UPDATED_ANSWER", componentId: keymap.willAskDoctor, newValue: "yes"}),
              ])
              await onReturnEdit(keymap.adbuScreenId, {[keymap.askedDoctor]: "no", [keymap.willAskDoctor]: "yes"})

              closeModal()
              setCurrentScreen("adbu_thank_you")
            }}
            onConfirmYes={async (closeModal) => {
              // can send these events at the same time
              await Promise.all([
                onEvent({type: "UPDATED_ANSWER", componentId: keymap.askedDoctor, newValue: "yes"}),
                onEvent({type: "UPDATED_ANSWER", componentId: keymap.doctorAttestation, newValue: "yes"})
              ])
              await onReturnEdit(keymap.adbuScreenId, {[keymap.askedDoctor]: "yes", [keymap.doctorAttestation]: "yes"})

              closeModal()
              if (answers.ddi) {
                setCurrentScreen("adbu_ddi")
              } else if (needsBloodPressure) {
                setCurrentScreen("blood_pressure_inputs")
              }
            }}
          />
        )
  
      case "adbu_ddi":
        return (
          <AdbuDDIScreen
            medications={getMedications(answers)}
            onBack={() => {
              setCurrentScreen("adbu_start")
            }}
            onSubmit={async ({ddiConfirm}) => {
              await onReturnEdit(keymap.ddiScreenId, {[keymap.ddiConfirm]: ddiConfirm})
              if (needsBloodPressure) {
                setCurrentScreen("blood_pressure_inputs")
              }
            }} />
        )
  
      case "adbu_thank_you":
        return (
          <AdbuThankYouScreen answers={answers} needsBloodPressure={needsBloodPressure} />
          )
  
      case "blood_pressure_start":
        return (
          <BloodPressureStartScreen onClick={() => setCurrentScreen("blood_pressure_inputs")} />
        )
  
      case "blood_pressure_inputs":
        return (
          <BloodPressureInputScreen
            setModal={setModal}
            onBack={() => {
              setCurrentScreen("blood_pressure_start")
            }}
            onSubmit={async ({systolic, diastolic, knowBpNumbers, bpNumbersRecent}) => {
              if (bpNumbersRecent !== "yes") { 
                setCurrentScreen("blood_pressure_start")
                return
              }

              await onReturnEdit(keymap.bloodPressureScreenId, {
                [keymap.systolic]: systolic,
                [keymap.diastolic]: diastolic,
                [keymap.knowBpNumbers]: knowBpNumbers,
                [keymap.bpNumbersRecent]: bpNumbersRecent
              })
            }} />
        )
    }
    return null
  })()
  
  return (
    <LayoutScreen
      showProfile={true}
      heroSrc={(() => {
        let hero = images.caution

        if (currentScreen === "adbu_thank_you" && !desktop) {
          hero = ""
        }

        if (currentScreen === "adbu_ddi" || currentScreen === "blood_pressure_inputs") {
          hero = ""
        }
      
        return hero
      })()}
      white={currentScreen === "adbu_thank_you" && !desktop}>
        { body }
        { currentScreen !== "blood_pressure_inputs" && createElement(referenceNumberComponent, {}, []) }
    </LayoutScreen>
  )
}

export default function OutcomePage(props) {
  const { outcome } = props.state

  let [searchParams, _] = useSearchParams()

  const returning = searchParams.get("returning") !== null
  
  if (outcome === "ok") {
    return <Purchase {...props} />
  }

  return <OutcomeFlow {...props} returning={returning} />
}