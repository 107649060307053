/** @jsxImportSource @emotion/react */
import '../css/Button.css'





export default function Button({type, variant, children, ...rest}) {
  return (
  <button 
    className={`btn ${variant}`} {...rest}>

    {children}
  </button>
  )
}
