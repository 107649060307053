import React, { Component, useContext, useState, useEffect } from 'react';
import Select from 'react-select'

import { registerCustomComponent, createComponent, AssessmentButton } from '../components/AssessmentComponents'
import RadioGroup, { dexterRadioGroup, CustomRadioGroup } from '../components/RadioGroup'
import {Screen} from '../components/Assessment'
import ModalContext from '../modal'
import { useStateWithSessionStorage } from '../hooks'
import {parsePath, createPath} from 'history'


const screenSpec = {
  title: "do you smoke cigarettes?",
  type: "Screen",
  showContinueButton: true,
  children: [{
    id: "smoking",
    type: "RadioGroup",
    options: [
      { label: "I don't smoke", subtext: "I never have a cigarette", value: "no" },
      { label: "I smoke regularly", subtext: "I have at least one cigarette just about every day", value: "regular" },
      { label: "I smoke occasionally", subtext: "I sometimes have a cigarette", value: "occasionally" },
      { label: "I rarely smoke", subtext: "I only smoke once a week", value: "rarely" },
    ],
  }],
}

const customScreenSpec = {
  title: "do you smoke cigarettes?",
  type: "Screen",
  showContinueButton: true,
  children: [{
    id: "smoking",
    type: "CustomRadioGroup",
    format: {
      type: "RowItem",
      children: [{
        type: "Icon",
      },{
        type: "Radio",
      }],
    },
    options: [
      { label: "I don't smoke", subtext: "I never have a cigarette", value: "no" },
      { label: "I smoke regularly", subtext: "I have at least one cigarette just about every day", value: "regular" },
      { label: "I smoke occasionally", subtext: "I sometimes have a cigarette", value: "occasionally" },
      { label: "I rarely smoke", subtext: "I only smoke once a week", value: "rarely" },
    ],
  }],
}

const customInputSpec = {
  type: "RowItem",
  children: [{
    type: "Icon",
  },{
    type: "TextfieldQuestion",
  },{
    type: "Span",
    style: {
      backgroundColor: "darkgray",
    },
  }],
}

const tmp = {
  id: "somescreen",
  title: "some title",
  showContinueButton: true,
  type: "Screen",
  children: [
    {
      id: "cancers",
      type: "CheckboxList",
      options: [
        {"value": "breast", "title": "Breast cancer", "icon": "report", "variant": "primary"},
        {"value": "endometrial", "title": "Endometrial (uterine) cancer", "variant": "primary"},
        {"value": "melanoma", "title": "Melanoma", "variant": "primary"},
        {"value": "ovarian", "title": "Ovarian cancer", "variant": "primary"},
        {"value": "lung-bronchial-adenocarcinoma", "title": "Lung/bronchial adenocarcinoma", "variant": "primary"},
        {"value": "meningioma", "title": "Meningioma", "variant": "primary"}
      ],
      none: {"title": "None of these", "variant": "primary"}
    }
  ]
}

registerCustomComponent("IconInput", customInputSpec)

const customInputScreenSpec = {
  title: "What is your weight?",
  type: "Screen",
  showContinueButton: true,
  children: [{
    id: "weight",
    type: "IconInput",
    text: "lbs",
    key: "idk",
  }],
}

function ModalTest() {
  const setModal = useContext(ModalContext)
  const createModal = () => <div>hi</div>
  return (
    <input type="button" onClick={() => setModal(createModal)}/>
  )
}


export default class Playground extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selected: '',
      answers: {},
    }
  }

  render() {
    const { selected, answers } = this.state

    const update = e => {
      console.log(e)
      switch (e.type) {
        case "UPDATED_ANSWER":
          return this.setState({ answers: {...answers, [e.componentId]: e.newValue} })
        default:
      }
    }

    const options = [{value: "1", label: "test"}]

    const onSubmit = () => console.log(this.state.answers)
    const props = {
      onSubmit,
      onEvent: update,
      getValue: qid => this.state.answers[qid],
    }
        //<Screen screenSpec={customInputScreenSpec} screenState={{answers}} onEvent={update} onSubmit={onSubmit}/>

    // return (
    //   <div style={{ maxWidth: "300px", margin: "0 auto"}}>
    //     { createComponent(customScreenSpec, props) }
    //     <Screen screenSpec={customScreenSpec} screenState={{answers}} onEvent={update} onSubmit={onSubmit}/>
    //     <Select options={options}/>
    //     <ModalTest/>
    //   </div>
    // )
    //
    //
        //{createComponent(tmp, props)}
    // return (
    //   <div style={{ maxWidth: "500px", margin: "0 auto"}}>
    //     <AssessmentButton/>
    //   </div>
    // )

    return <TestComponent />
  }
}

function PersistantCounter() {
  const [n, setn] = useStateWithSessionStorage("n", 0)

  return (
    <div>
      <div>count: {n}</div>
      <button onClick={(e) => {
        e.preventDefault()
        setn(n => n + 1)
      }}>up</button>
    </div>
  )
}

function TestComponent() {
  const [show, setShow] = useState(true)
  return (
    <div>
      {show && (
        <PersistantCounter />
      )}
      <button onClick={(e) => {
        e.preventDefault()
        setShow(show => !show)
      }}>{show ? 'hide' : 'show'}</button>
    </div>
  )
}