import { useState } from 'react'
import Textfield, { WithLabel } from '../components/Textfield'
import Button from '../components/Button'

export default function CreateAccount({authClient}) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const reset = () => {
    setEmail('')
    setPassword('')
    setConfirmPassword('')
  }

  const createAccount = () => {
    authClient.createUser({email: email, password: password})
      .then(response => alert('user created successfully!'))
      .catch(e => console.log(e))
      .finally(reset)
  }
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{width: '300px', border: 'solid 5px whitesmoke', borderRadius: '20px', padding: '40px', display: 'flex', flexDirection: 'column', gap: '20px'}}>
        <WithLabel label="Email">
          <Textfield value={email} aria-label="Email" onChange={e => setEmail(e.target.value)} />
        </WithLabel>
        <WithLabel label="Password">
          <Textfield value={password} aria-label="Password" onChange={e => setPassword(e.target.value)} type="password" />
        </WithLabel>
        <WithLabel label="Confirm Password">
          <Textfield value={confirmPassword} aria-label="Confirm Password" onChange={e => setConfirmPassword(e.target.value)} type="password" />
        </WithLabel>
        <Button disabled={!password || password !== confirmPassword} onClick={createAccount}>Create Account</Button>
      </div>
    </div>
  )
}
