import { useState, useEffect } from 'react'

export function useGetAssessmentById(api, id) {
  const [assessment, setAssessment] = useState(undefined)
  useEffect(() => {
    async function getById() {
      const response = await api.getById(id)
      setAssessment(response)
    }
    getById()
  }, [id])
  return [assessment, setAssessment]
}

export function useStateWithSessionStorage(key, value) {
  // get saved state or initialize with value
  const init = () => {
    const saved = window.sessionStorage.getItem(key)
    if (saved) {
      return JSON.parse(saved)
    }
    return value
  }

  const [state, setState] = useState(init)

  // save state on change
  useEffect(() => {
    if (state !== undefined) {
      window.sessionStorage.setItem(key, JSON.stringify(state))
    }
  }, [state])

  // cleanup on unmount
  useEffect(() => {
    return () => {
      window.sessionStorage.removeItem(key)
    }
  }, [])

  return [state, setState]
}