import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { client } from '../client'
import { decodeAssessmentState } from './Assessment'
import WelcomeRedirect from './WelcomeRedirect'

export default function PurchaseGuard({children}) {
  const [ok, setOk] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const { assessmentId } = useParams()

  useEffect(() => {

    (async () => {
      if (!assessmentId) {
        setLoaded(true)
        return
      }

      let resp
      try {
        resp = await client.getState({assessmentid: assessmentId})
      } catch (e) {
        console.error(e)
        setLoaded(true)
        return
      }

      const state = decodeAssessmentState(resp.state)
      setOk(state.outcome === "ok")
      setLoaded(true)
    })()

  }, [])

  if (!ok) {
    if (!loaded) return null

    return <WelcomeRedirect />
  }  

  return children
}