import React, { Component, useEffect, useContext, useState, createElement, useRef, forwardRef } from 'react'

import '../css/RadioList.css'
import '../css/Checkbox.css'
import '../css/SelectOption.css'
import '../css/SelectList.css'
import Button from './Button'
import Textfield from './Textfield'
import icons from '../icn/index'
import images from '../img/index'
import { MoreInfoIcon, MoreInfoIconPlusComponent, TitleInfo, TitleInfoTooltip, BloodPressureInfo } from './MoreInfo'
import { AssessmentButton, AssessmentScreenTitle, Screen, KickoutScreen } from './Assessment'

import useResponsive from '../responsive'

export {AssessmentButton, AssessmentScreenTitle}


export function ComponentColumn({children, style}) {
  return <div className='column' style={{ display: 'flex', flexDirection: 'column', ...style}}>{children}</div>
}

export function Image({src}) {
  return <img src={images[src]} alt="" style={{width: "100%", height: "auto"}}/>
}

export function Div({children, style}) {
  return <div style={style}>{children}</div>
}

export function ComponentSpacer({ style={} }) {
  return (
    <hr style={{ border: "none", width: "calc(100% - 8px)", height: "2px", backgroundColor: "var(--black)", margin: "auto 4px", ...style}} />
  )
}

export function ComponentLink({ text, href, style={} }) {
  return (
    <a href={href} style={style}>{ text }</a>
  )
}

export function ComponentList({ ordered=false, style={}, options, title}) {
  const titleStyle = {
    marginBottom: "12px",
    ...style.title
  }
  return (
    ordered
    ? (
      <div style={style.root}>
      {title && <p style={titleStyle}>{title}</p>}
      <ol style={style.list || {}}>
        {
          options.map(opt => (
            <li style={style.item || {}} key={opt} dangerouslySetInnerHTML={{__html: opt}}></li>
          ))
        }
      </ol>
      </div>
    )
    : (
      <div style={style.root}>
      {title && <p style={titleStyle}>{title}</p>}
      <ul style={style.list || {}}>
        {
          options.map(opt => (
            <li style={style.item || {}} key={opt} dangerouslySetInnerHTML={{__html: opt}}></li>
          ))
        }
      </ul>
      </div>
    )
  )
}

function ComponentRow({justify, children, style}) {
  var j = 'flex-start'
  if (justify === 'center') {
    j = 'center'
  } else if (justify === 'end') {
    j = 'flex-end'
  }
  return <div style={{display: 'flex', justifyContent: j, ...style}}>{children}</div>
}

function Paragraph({text, style, icon}) {
  return (
    <p style={style} dangerouslySetInnerHTML={{__html: text}}>
    </p>
  )
}

function range (a, b) {
  const ret = []
  for (var i = a; i < b; i++) {
    ret.push(i)
  }
  return ret
}

export function OptionSelect({ id, question, placeholder, icon, variant, style={}, options, value, onChange }) {
  const placeholderOption = {
    disabled: true,
    hidden: true,
    text: placeholder,
    value: "placeholder",
  }
  options = [placeholderOption, ...options]
  return (
    <div className={`select ${variant}`} style={{ ...style.list}}>
      { question && <p className="question">{ question }</p> }
      <label>
        <span className="sr-only">Choose your height</span>
        { icon && icons[icon] && <img className="icon" src={icons[icon]} alt="" />}
        <select
          name={id}
          style={{ paddingLeft: icon ? '56px' : '16px' }}
          value={value || "placeholder"}
          onChange={e => onChange(e.target.value)}
        >
          {
            options.map(opt => (
              <option
                key={opt.value}
                value={opt.value}
                disabled={opt.disabled}
                hidden={opt.hidden}
              >{ opt.text }</option>
            ))
          }
        </select>

        <img className="chevron" src={icons['chevron-down']} alt={`open ${id} menu`} />
      </label>
    </div>
  )
}

export function HeightSelect(props) {
  const optionRange = range(58, 58 + 24)
    .map(value => ({value, text: `${value/12|0} feet, ${value%12} inches`}))

  const {id, onEvent} = props
  const handleChange = value => {
    onEvent({componentId: id, type: "UPDATED_ANSWER", newValue: value})
  }
  return <OptionSelect options={optionRange} onChange={handleChange} {...props} />
}

function Label({htmlFor, label, style={} }) {
  return <label htmlFor={htmlFor} style={style}>{label}</label>
}

function WeightInput(props) {
  const { desktop } = useResponsive()

  const {style, ...rest} = props
  const _style = {...style}
  if (desktop) {
    _style.errMsg = {
      ..._style.errMsg,
      whiteSpace: "nowrap",
    }
  }
  return <NumericInput {...rest} style={_style} />
}

export function NumericInput({id, question, infoLink, placeholder, icon, variant, style={}, units, value="", err, hideErrs, onEvent, maxLength, nextFocus, hideLabel=false}) {
  const inputStyle = {...style.input}
  const unitsStyle = {}
  if (err) {
    inputStyle.borderColor = "#ED0000CC"
    inputStyle.backgroundColor = "#FFE9E9"

    unitsStyle.borderLeft = "1px solid #ED0000CC"
  }

  const onChange = e => {
    const numbers = "0123456789"
    const v = [].slice.call(e.target.value)
      .filter(x => numbers.indexOf(x) >= 0)
      .join("")

    if (value === undefined && v === '') {
      return
    }

    if (v == value) { return }

    if (nextFocus && v.length === maxLength) {
      const el = document.getElementById(nextFocus)
      el.focus()
    }
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: v })
  }

  return (
    <div
      style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", ...style.base || {} }} >
      {!hideLabel &&
        <label htmlFor={id} style={{ marginBottom: "12px", ...style.label}} dangerouslySetInnerHTML={{__html: question}}>
        </label>
      }
      { infoLink && <a href={ infoLink.href } style={style.infoLink || {}}>{ infoLink.text }</a> }
      <div style={{ display: "flex", width: "100%", maxWidth: "500px" }}>
        <Textfield
          id={id}
          value={value || ""}
          variant={variant}
          inputMode="numeric"
          pattern="[0-9]*"
          style={{input: inputStyle, units: unitsStyle}}
          placeholder={placeholder || ""}
          units={units}
          icon={icon && icons[icon] ? icons[icon] : null}
          onChange={onChange}
          onFocus={() => hideErrs()}
          maxLength={maxLength}
        />
      </div>
      {err && (
        <span style={{
          color: "#ED0000",
          marginTop: "16px",
          ...style.errMsg
        }}>{err}</span>
      )}
    </div>
  );
}

export function RadioList({id, options, value, onSelect}) {
  const handleEvent = e => {
    if (e.type === "UPDATED_ANSWER") {
      onSelect(e.newValue)
    }
  }
  return (
    <RadioSelectList id={id} options={options} value={value} variant="primary" onEvent={handleEvent}/>
  )
}

export function RadioSelectList({id, question, infoLink, style={}, options, value, variant, onEvent}) {
  return (
    <div className={`radio-list ${variant}`} style={{ ...style.list || {}}}>
      { question && <p style={style.question || {}}>{question}</p> }
      { infoLink && <a href={ infoLink.href }>{ infoLink.text }</a> }

      <div className='radio-group' role="radiogroup">
        {
          options.map(opt => (
            <label key={opt.value} htmlFor={opt.value} className="radio-item" style={style.item || {}}>
              { opt.icon && icons[opt.icon] && <img src={icons[opt.icon]} alt="" /> }
              <div>
                <div style={{ flex: '1', marginRight: '16px' }}>
                  <h2>{ opt.title }</h2>
                  { opt.text && <p>{ opt.text }</p> }
                </div>
                {opt.rightText && <span className="right-text">{opt.rightText}</span>}
                <div className="radio-container">
                  <input
                    type="radio"
                    id={opt.value}
                    name={opt.value}
                    value={opt.value}
                    checked={value === opt.value}
                    aria-checked={value === opt.value}
                    onChange={(e) => onEvent({componentId: id, type: 'UPDATED_ANSWER', newValue: e.target.value})}
                  />
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
                    <path d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32V32C7.16344 32 0 24.8366 0 16V16Z"/>
                    <path d="M11 17L14 20L20.5 13" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>
            </label>
          ))
        }
      </div>
    </div>
  )
}

// TODO maybe just embed this in CheckboxList?
export function Checkbox({title, text, value, icon, variant, onCheck, checked, moreInfo, style={}, reversed=false}) {
  let m
  if (moreInfo) {
    m = createComponent(moreInfo)
  }

  if (reversed) {
    style.labelContainer = {flexDirection: "row-reverse"}
  }

  return (
    <label className={`checkbox ${variant}`} style={{ padding: icon ? "0" : '0 0 0 56px', ...style.label }}>
      { icon && icons[icon] && <img src={icons[icon]} alt="" /> }
      <div className='label-container' style={style.labelContainer}>
        <div className='text-container'>
          <div style={{display: "flex", flexWrap: "wrap", gap: "4px", alignItems: "center"}}>
            <p className='title'>{ title }</p>
            {moreInfo && m}
          </div>
          <p className='text' style={style.text}>{ text }</p>
        </div>
        <div className="checkbox-container" style={style.checkboxContainer}>
          <input value={value} type="checkbox" checked={checked} onChange={e => onCheck(e.target.checked)} />
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
            <path d="M9 1.5H23V0.5H9V1.5ZM30.5 9V23H31.5V9H30.5ZM23 30.5H9V31.5H23V30.5ZM1.5 23V9H0.5V23H1.5ZM9 30.5C4.85786 30.5 1.5 27.1421 1.5 23H0.5C0.5 27.6944 4.30558 31.5 9 31.5V30.5ZM30.5 23C30.5 27.1421 27.1421 30.5 23 30.5V31.5C27.6944 31.5 31.5 27.6944 31.5 23H30.5ZM23 1.5C27.1421 1.5 30.5 4.85786 30.5 9H31.5C31.5 4.30558 27.6944 0.5 23 0.5V1.5ZM9 0.5C4.30558 0.5 0.5 4.30558 0.5 9H1.5C1.5 4.85786 4.85786 1.5 9 1.5V0.5Z" />
            <path d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"/>
            <path d="M11 17L14 20L20.5 13" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
    </label>
  )
}

export function CheckboxList({id, question, options, none, onEvent, value}) {
  const isNoneChecked = value !== undefined && value.length === 0

  const onCheckRegular = (opt, checked) => {
    let newValue
    if (checked) {
      const currentValues = value === undefined ? [] : value
      newValue = [...currentValues, opt.value]
    } else {
      newValue = value.filter(v => v !== opt.value)
      if (newValue.length === 0) {
        newValue = undefined
      }
    }
    onEvent({componentId: id, type: "UPDATED_ANSWER", newValue})
  }
  const children = options.map((opt, i) => <Checkbox {...opt} key={i} value={opt.value} onCheck={checked => onCheckRegular(opt, checked)} checked={value !== undefined && value.includes(opt.value)} />)

  if (none) {
    const onCheckNone = checked => {
      let newValue
      if (checked) {
        newValue = []
      } else {
        newValue = undefined
      }
      onEvent({componentId: id, type: "UPDATED_ANSWER", newValue})
    }
    children.push(<Checkbox {...none} key={options.length} onCheck={onCheckNone} checked={isNoneChecked} />)
  }

  const style = {
    border: "1px solid rgba(39, 39, 39, 0.16)",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF"
  }
  return (
    <div style={style}>{children}</div>
  )
}


function Span({text, style}) {
  return ( <span style={style}> {text} </span> )
}


const components = {
  Button: AssessmentButton,
  Checkbox: Checkbox,
  CheckboxList,
  Div,
  BloodPressureInfo,
  Column: ComponentColumn,
  Image: Image,
  KickoutScreen,
  Link: ComponentLink,
  List: ComponentList,
  //ModalConfirm,
  //MoreInfo,
  MoreInfoIcon,
  MoreInfoIconPlusComponent,
  NumericInput,
  WeightInput,
  HeightSelect,
  Paragraph,
  RadioSelectList,
  Row: ComponentRow,
  Screen,
  Spacer: ComponentSpacer,
  Span,
  Title: AssessmentScreenTitle,
  TitleInfo,
  TitleInfoTooltip,
  Label,
}

export function registerComponent(name, def) {
  components[name] = def
}

export function registerCustomComponent(name, def) {
  const fn = (props) => {
    return createComponent(def, props)
  }

  fn.displayName = name
  components[name] = fn
}

export const ComponentContext = React.createContext({});
//getValue, onEvent, onSubmit
export function createComponent(componentspec, props={}) { // create read only component

  // don't pass key through the tree, or else we get children w/ dupe keys
  //const { key, ...rest } = props
  const rest = Object.assign({}, props, {key: undefined})

  var renderedchildren = []
  if (componentspec.children) {
    renderedchildren = componentspec.children.map(c => createComponent(c, rest))
  }

  const component = components[componentspec.type]
  if (component === undefined) {
    console.error("component missing", componentspec.type)
    return null
  }

  const { children, ...specProps } = componentspec
  const mergedProps = {
    ...specProps,
    ...props,
  }

  if (componentspec.id) {
    //FIXME:
    mergedProps.value = props.getValue && props.getValue(componentspec.id)
    mergedProps.key = componentspec.id

    const err = props.screenErrs?.[componentspec.id]
    if (err && props.shouldShowErrs) {
      mergedProps.err = err
    }
  }

  return createElement(component, mergedProps, ...renderedchildren)
}
