import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext, useNavigate } from 'react-router-dom';

import { createComponent,  AssessmentButton, Checkbox } from './components/AssessmentComponents'
import ModalContext from './modal'
import { useAudit } from './audit'
import router from './history'


export function useBlocker( blocker, when = true ) {
  const { navigator } = useContext( NavigationContext );

  useEffect( () => {
    if ( ! when ) return;

    const unblock = navigator.block( ( tx ) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker( autoUnblockingTx );
    } );

    return unblock;
  }, [ navigator, blocker, when ] );
}

const buttonContainer = {
  width: "100%",
  margin: "0 auto",
  paddingTop: "16px",
}

let skipNext = false

export function skipPrompt() {
  skipNext = true
}

export function ExitModal({title, text, onCancel, onConfirm, cancelText="Close", confirmText="Leave"}) {
  return (
    <>
      <h2 style={{color: "var(--secondary)"}}>
        {title}
      </h2>

      <p>
        {text}        
      </p>

      <div style={buttonContainer}>
        <AssessmentButton onClick={onConfirm} variant="primary">{confirmText}</AssessmentButton>
      </div>

      <div style={buttonContainer}>
        <AssessmentButton onClick={onCancel} variant="primary outline">{cancelText}</AssessmentButton>
      </div>
    </>
  )
}

export function usePrompt(Component, when = true) {
  const setModal = useContext(ModalContext)
  const onEvent = useAudit()

  const blocker = useCallback(
    ( tx ) => {
      if (skipNext) {
        skipNext = false
        tx.retry()
        return
      }

      const close = () => setModal()

      const exit = () => {
        setModal()
        onEvent({type: "EXIT_CONFIRM"})
        skipPrompt()

        // remove returning param when exiting
        const params = new URLSearchParams(window.location.search)
        params.delete("returning")

        const search = params.toString() ? "?" + params.toString() : ""
        router.push("/" + search)
      }

      setModal(() => <Component close={close} exit={exit} />)

    },
    [Component]
  );

  useBlocker(blocker, when);
}

let skipNextRedirect = false

export function useRedirect(to, when = true) {
  const blocker = useCallback((tx) => {
    if (skipNextRedirect) {
      skipNextRedirect = false
      tx.retry()
      return
    }
    skipNextRedirect = true
    router.push(to)
  }, [to])

  useBlocker(blocker, when)
}

export function AssessmentPrompt({close, exit}) {
  return (
    <ExitModal 
      title="You are about to leave the health survey"
      text="If you leave, your information will not be saved. Are you sure you want to exit?"
      onCancel={close}
      onConfirm={exit}
    />
  )
}
