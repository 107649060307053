import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import WelcomeRedirect from "./WelcomeRedirect"

// e.g. prescreen = {
//   assessmentId: 'someid',
//   welcome: true
//   // ...
// }
export function getPrescreenState() {
  let prescreen
  try {
    prescreen = window.sessionStorage.getItem("prescreen")
    if (prescreen !== null) {
      prescreen = JSON.parse(prescreen)
    } else {
      prescreen = {}
    }
  } catch (e) {
    prescreen = {}
  }
  return prescreen
}

export function setPrescreenState(fn=(prescreen) => ({...prescreen})) {
  const current = getPrescreenState()
  const next = fn(current)

  window.sessionStorage.setItem("prescreen", JSON.stringify(next))
}

export default function PrescreenGuard({required="", children}) {
  const states = {
    LOADING: 0,
    OK: 1,
    REDIRECT: 2
  }

  const [state, setState] = useState(states.LOADING)
  const assessment = useSelector(state => state.assessment)

  // run once on mount, otherwise the guard will redirect to welcome after the assessment starts (assessment state is cleared on start)
  useEffect(() => {
    const prescreen = getPrescreenState()
    if (prescreen.assessmentId == assessment.assessmentId && prescreen[required]) {
      setState(states.OK)
    } else {
      setState(states.REDIRECT)
    }

  }, [])

  switch (state) {
    case states.OK: 
      return children
    case states.REDIRECT:
      return <WelcomeRedirect id="prescreen-guard" />
    default:
      return null
  }
}