import { createBrowserHistory } from 'history';

const history = createBrowserHistory()

function makesearch(params={}) {
  const p = new URLSearchParams(window.location.search)
  
  for (const k in params) {
    p.set(k, params[k])
  }

  let s = p.toString()
  if (s !== "") {
    s = "?" + s
  }
  return s
}

export function routerpush(to, params={}) {
  // pushes url 'to' with params window.location.search + params
  history.push(to + makesearch(params))
}

export function routerreplace(to, params={}) {  
  // like routerpush but with replace
  history.replace(to + makesearch(params))
}

export default history
