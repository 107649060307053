import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { Assessment, KickoutScreen } from '../components/Assessment'
import SummaryPage from './Summary'
import OutcomePage from './Outcome'
import { decodeAssessmentState, encodeScreenState } from '../components/Assessment'
import { createComponent } from '../components/AssessmentComponents'
import LayoutScreen from '../components/Layout'
import AuditContext from '../audit'
import images from '../img/index'
import { client } from '../client'
import history from '../history'
import { toast } from 'react-toastify'
import { usePrompt, AssessmentPrompt } from '../prompt'
import WelcomeRedirect from '../components/WelcomeRedirect'

function formatAssessmentNumber(n) {
  if (n < 0) { return "" }
  if (n > 99) { return n }

  return ("00" + n).slice(-3)
}

function ClinicianCompletionToast() {
  return (
    <div style={{fontSize: "16px", lineHeight: "initial"}}>
      You have successfully completed this health survey.
      <a href="#" style={{display: "block", marginTop: "8px"}} onClick={e => {
        e.preventDefault()
        const s = window.open("", "_self")
        s.close()
      }}>Click here to return to the Study Administration Portal</a>
    </div>
  )
}

function logError(e) {
  console.log(e)
}

export default function AssessmentPage() {
  const { assessmentId } = useParams()
  const [spec, setSpec] = useState({children: [{type: "Screen", id: "loading"}], loading: true})
  const [state, setState] = useState({state: "assessment", isTaking: true, index: 0, outcome: "", loading: true})
  const [summaryIds, setSummaryIds] = useState([])
  const [doctorAttestation, setDoctorAttestation] = useState('')
  const [assessmentNumber, setAssessmentNumber] = useState(-1)
  const [searchParams, setSearchParams] = useSearchParams()
  const [recordType, setRecordType] = useState('')
  
  const navigate = useNavigate()

  useEffect(() => {
    // delete the auth param since it's only needed before entering the assessment
    const p = new URLSearchParams(searchParams.toString())
    p.delete("auth")
    setSearchParams(p)
  }, [])

  useEffect(() => {
    if (state.isCompleted && recordType === "clinician") {
      toast(<ClinicianCompletionToast />, {autoClose: false, closeOnClick: false, draggable: false})
    }
  }, [state.isCompleted, recordType])

  useEffect(() => {
    client.getState({assessmentid: assessmentId})
      .then(response => {
        const remoteSpec = JSON.parse(response.spec)
        const remoteState = decodeAssessmentState(response.state)
        setSpec(remoteSpec)
        setState(remoteState)
        setRecordType(response.recordType)
        setAssessmentNumber(response.assessmentnumber)
      })
      .catch(logError)
  }, [])

  useEffect(() => {
    if (state && state.isReviewing) {
      client.getSummary({assessmentid: assessmentId})
        .then(response => {
          setSummaryIds(response.summaryids)
        })
        .catch(logError)
    }
  }, [state])

  useLayoutEffect(() => {
    window.scrollTo(0,0)
  })

  const onEvent = (e) => {
    console.log(e)
    client.recordEvent({assessmentid: assessmentId, eventtype: e.type, data: JSON.stringify(e)})
      .catch(logError)
  }

  usePrompt(AssessmentPrompt, state && !state.isKickout)
  
  const onSubmit = (answers) => {
    const encoded = encodeScreenState(answers)
    client.submitScreen({assessmentid: assessmentId, screenstate: encoded})
      .then(response => {
        const decoded = decodeAssessmentState(response.state)
        setState(decoded)
      })
      .catch(logError)
  }

  const onEdit = (index) => {
    client.editScreen({assessmentid: assessmentId, index: index})
      .then(response => {
        const nextState = decodeAssessmentState(response.state)
        setState(nextState)
      })
      .catch(logError)
  }

  const onReturnEdit = (screenId, answers) => {
    return client.returningEdit({assessmentId, screenId, answers: JSON.stringify(answers)})
      .then(response => {
        const nextState = decodeAssessmentState(response.state)
        setState(nextState)
      })
  }

  const onConfirm = () => {
    client.finishAssessment({recordid: assessmentId})
      .then(res => {
        const decoded = decodeAssessmentState(res.state)
        setState(decoded)
      })
      .catch(logError)
  }

  const onBack = () => {

    if ((state && state.index === 0) || state.isKickout || state.isOutcome || state.state == "returningedit") {
      navigate('/')
      return
    }

    client.goBack({assessmentid: assessmentId})
      .then(res => {
        const decoded = decodeAssessmentState(res.state)
        setState(decoded)
      })
      .catch(logError)
  }

  let assessmentScreen = undefined
  if (state.isTaking || state.isEditing) {
    assessmentScreen = spec.children[state.index]
  }

  let dnuScreen = undefined
  if (state.isKickout) {
    dnuScreen = spec.kickoutScreens.filter(x => x.id == state.kickoutReason)[0]
  }

  let currentScreen = assessmentScreen || dnuScreen
  let heroSrc = currentScreen && currentScreen.heroImage ? images[currentScreen.heroImage] : undefined

  const canGoBack = state.isTaking || state.isEditing
  const hidingBackButton = currentScreen && !!currentScreen.hideBackButton

  const showBackButton = canGoBack && !hidingBackButton
  const showProfile = !state.isKickout && !state.isOutcome
  const className = state.isTaking || state.isEditing ? "assessment-main" : "assessment-completed"

  const onComponentEvent = e => {
    let ev = e
    if (state.isTaking || state.isEditing) {
      const screenId = spec.children[state.index].id
      ev = {...ev, screenId}

      if (e.type == "MOREINFO_DISPLAYED") {
        const componentId = `${screenId}.moreInfo`
        ev = {...ev, screenId, componentId}
      }
    }
    onEvent(ev)
  }

  const AssessmentReferenceNumber = () => (
    <div style={{padding:`${state.outcome == "ok" ? 64 : 0}px 32px 0 32px`}}>
      <span style={{fontWeight: "bold", fontSize: "20px"}}>
        Assessment ID - {`${formatAssessmentNumber(assessmentNumber)}`}
      </span>
    </div>
  )

  return (
    <AuditContext.Provider value={onComponentEvent}>
    <main className={className} style={{display: "flex", flexDirection: "column", flex: 1}}>
      {
        state.outcome == "" ? (
          <LayoutScreen heroSrc={heroSrc} showProfile={showProfile}>
        { !spec.loading && !state.loading &&
        <div>
          {
            (state.isTaking || state.isEditing) &&
              <Assessment spec={spec} state={state} showBackButton={showBackButton} onSubmit={onSubmit} onEvent={onEvent} onBack={onBack}/>
          }
          {
            state.isReviewing &&
              <SummaryPage spec={spec} summaryIds={summaryIds} answers={state.answers} onEdit={onEdit} onConfirm={onConfirm} recordType={recordType} />
          }
          {
            state.isKickout &&
              createComponent(spec.kickoutScreens.filter(x => x.id == state.kickoutReason)[0], {onEvent})
          }
          { (state.isKickout || state.outcome != "") && <AssessmentReferenceNumber /> }
        </div>
        }
      </LayoutScreen>
        ) : (
          <OutcomePage
            spec={spec}
            state={state}
            recordType={recordType}
            doctorAttestation={doctorAttestation}
            onReturnEdit={onReturnEdit}
            assessmentId={assessmentId}
            referenceNumberComponent={AssessmentReferenceNumber} />
        )
      }
      
    </main>
    </AuditContext.Provider>
  )
}
