import '../css/TextField.css'

export function WithLabel({id, label, children}) {
  return (
    <div>
      <label htmlFor={id} style={{display: 'block', fontSize: '18px', marginBottom: '8px'}}>{label}</label>
      {children}
    </div>
  )
}


export default function Textfield({ style={}, variant, ...rest }) {
  return (
    <div className={`text-field ${variant}`} style={style.root}>
      { rest.icon && <img className="icon" src={rest.icon} alt="" />}
      <input type="text" style={{ paddingRight: rest.units ? "64px" : "19px", paddingLeft: rest.icon ? "56px" : "19px", ...style.input}} {...rest} />
      { rest.units && <span className="units" style={style.units}>{rest.units}</span> }
    </div>
  );
}
