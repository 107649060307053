import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom'

// https://github.com/remix-run/react-router/issues/8264#issuecomment-991271554
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { Provider } from 'react-redux'

import Modal from 'react-modal';

import LandingPage from './pages/LandingPage';
import {WelcomeScreen, PrivacyScreen, KnowNumbersScreen, KnowNumbersMoreInfoScreen, HasUsedProductScreen} from './pages/LandingPage';
import AssessmentPage from './pages/Assessment'
import Outcome from './pages/Outcome' // todo is this unused here?
import CreateAccount from './pages/CreateAccount'
import Experimental from './pages/Experimental'
import Playground from './pages/Playground'
import ReturnPage from './pages/Return'
import './App.css'
import './css/NavBar.css'
import './css/Modal.css'
import ModalContext, { ModalProvider } from './modal'
import Checkout from './pages/CheckoutTmp'
import QRCodePage from './pages/QRCode'

import history from './history'
import store from './store'
import { client, authClient } from './client'
import config from './config'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PrescreenGuard from './components/PrescreenGuard'
import PurchaseGuard from './components/PurchaseGuard'
import WelcomeRedirect from './components/WelcomeRedirect'

Modal.setAppElement(document.getElementById('root'))

fetch("/config/config.json")
  .then(res => res.json())
  .then(config => store.dispatch({type: "FETCH_CONFIG_SUCCESS", config}))

function CloseButton({onClick}) {
  return (
    <button className="unstyled primary focusable" onClick={() => onClick()}>
      <span className="sr-only">Close</span>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.742445 2.30104C0.332404 1.891 0.332403 1.22619 0.742444 0.816153V0.816153C1.15248 0.406114 1.81729 0.406114 2.22733 0.816153L14.1064 12.6952C14.5165 13.1052 14.5165 13.7701 14.1064 14.1801V14.1801C13.6964 14.5901 13.0316 14.5901 12.6215 14.1801L0.742445 2.30104Z" fill="#534299"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.6247 0.816662C13.0348 0.406622 13.6996 0.406621 14.1096 0.816661V0.816661C14.5197 1.2267 14.5197 1.8915 14.1096 2.30154L2.23055 14.1806C1.82051 14.5906 1.1557 14.5907 0.745661 14.1806V14.1806C0.335621 13.7706 0.335621 13.1058 0.745661 12.6957L12.6247 0.816662Z" fill="#534299"/>
      </svg>
    </button>
  )
}

function Root() {
  return (
    <ModalProvider><Outlet/></ModalProvider>
  )
}

function Debug({children}) {
  // shows debug information
  return (
    <div style={{color: "red", background: "white", border: "solid 1px black", minWidth: "300px", display: "inline-block", padding: "16px", position: "fixed", bottom: "16px", right: "16px"}}>
      {children}
    </div>
  )
}

export function Toasts() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Slide}
    />
  )
}

const showDebug = false

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage/>}>
        <Route index element={<WelcomeScreen/>} />
        <Route path="privacy" element={
          <PrescreenGuard required="welcome">
            <PrivacyScreen/>
          </PrescreenGuard>
        }/>
        <Route path="numbers" element={
          <PrescreenGuard required="privacy">
            <KnowNumbersScreen/>
          </PrescreenGuard>
        }/>
        <Route path="numbers/more-info" element={
          <PrescreenGuard required="privacy">
            <KnowNumbersMoreInfoScreen/>
          </PrescreenGuard>
        }/>
        <Route path="product" element={
          <PrescreenGuard required="numbers">
            <HasUsedProductScreen/>
          </PrescreenGuard>
        }/>
        <Route path="deactivated" element={<WelcomeScreen deactivated={true} />} />
      </Route>

      <Route path="assessment/:assessmentId/" element={<ModalProvider key="assessment"><AssessmentPage/></ModalProvider>} />
      <Route path="return/:assessmentId" element={<ReturnPage/>} />
      <Route path="create-account" element={<CreateAccount authClient={authClient} />} />

      <Route path="experimental" element={<Experimental client={client} />} />
      <Route path="play" element={<Playground />} />
      <Route path="outcome" element={<Outcome />} />
      <Route path="checkout/:assessmentId" element={
        <PurchaseGuard>
          <Checkout />
        </PurchaseGuard>
      }/>
      <Route path="qr/:assessmentId" element={
        <PurchaseGuard>
          <QRCodePage />
        </PurchaseGuard>
      } />
      <Route path="*" element={<WelcomeRedirect />} />
    </Routes>
  )
}

function App() {
  return (
    <Provider store={store}>
    <div className='App'>
      {showDebug && (
        <Debug>
          <pre>config: {JSON.stringify(config, null, 2)}</pre>
        </Debug>
      )}
      <ModalProvider key="default">
      <HistoryRouter history={history}>
        <AppRoutes/>
      </HistoryRouter>
      </ModalProvider>
      <Toasts />
    </div>
    </Provider>
  )
}

export default App
