import RouterLink from './RouterLink'
import icons from '../icn/index'

export function BackArrow() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 15L1 8M1 8L8 1M1 8H15" stroke="#0E5340" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

function NavBar({ variant, onBack, isAlt, showProfile=true, style={}, profile="profile" }) {
    const backStyle = {}
    if (!onBack) {
      backStyle.visibility = "hidden"
    }
    return (
      <header className={`header ${variant} ${isAlt ? "alt" : ""}`} style={style.header}>
        <nav aria-label='main navigation'>
          <ul>
            <li style={backStyle}>
              <button aria-label='back' onClick={() => onBack()}>
                <div className='container back-arrow'>
                  <BackArrow />
                  <span className='sr-only'>back</span>
                </div>
              </button>
            </li>

              {/* <li>
                <RouterLink to="/dashboard">
                  Dashboard
                </RouterLink>
              </li> */}

              <li style={{visibility: !showProfile ? "hidden" : ""}}>
              <RouterLink to="/create-account">
                <div className='container'>
                  <img src={icons[profile]} alt="" height="20" width="20"/>
                  <span className='sr-only'>create account</span>
                </div>
              </RouterLink>
            </li>
          </ul>
        </nav>
      </header>
    )
  }

  export default NavBar
