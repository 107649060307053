import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux' 

import { client } from './client'

export function useMetrics(update) {
  const { assessmentId } = useParams()

  const paramAssessmentId = assessmentId
  
  const recordMetric = async (update, assessmentId) => {
    if (!assessmentId) {
      assessmentId = paramAssessmentId
    }
    await client.recordMetric({assessmentid: assessmentId, data: JSON.stringify(update)})
  }

  useEffect(() => {
    if (update) {
      recordMetric(update)
    }  
  }, [])

  return recordMetric
}
