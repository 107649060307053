import { useState, useEffect } from 'react'
import LayoutScreen from '../components/Layout'
import Radio from '../components/Radio'
import { AssessmentScreenTitle, OptionSelect, RadioList, Checkbox  } from '../components/AssessmentComponents'
import useResponsive from '../responsive.js'
import { CardView } from './Checkout.js'
import { checkoutClient, client } from '../client'
import history from '../history'
import Textfield from '../components/Textfield';
// import '../css/TextField.css'
import images from '../img'

import {loadStripe} from '@stripe/stripe-js';
import {Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import Progress, {SmoothProgress} from '../components/Progress.js'
import { usePrompt, ExitModal, skipPrompt } from '../prompt'

// TODO - better filename

const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

const DEBUG = false

function CardList({children}) {
  const style = {
    backgroundColor: "#FFFFFF",
    border: "1px solid var(--black-16)",
    borderRadius: "8px",
    padding: "16px"
  }
  return (
    <div style={style}>
      {children}
    </div>
  )
}

export function CheckoutPrompt({close, exit}) {
  return (
    <ExitModal 
      title="You are about to leave the checkout process."
      text="If you leave now, your information may not be saved. Are you sure you want to quit checkout now?"
      onCancel={close}
      onConfirm={exit}
      cancelText="Back"
      confirmText="Yes, Leave"
    />
  )
}

function StateSelect({value, onChange}) {
  const options = [
    {
        "text": "Alabama",
        "value": "AL"
    },
    {
        "text": "Alaska",
        "value": "AK"
    },
    {
        "text": "American Samoa",
        "value": "AS"
    },
    {
        "text": "Arizona",
        "value": "AZ"
    },
    {
        "text": "Arkansas",
        "value": "AR"
    },
    {
        "text": "California",
        "value": "CA"
    },
    {
        "text": "Colorado",
        "value": "CO"
    },
    {
        "text": "Connecticut",
        "value": "CT"
    },
    {
        "text": "Delaware",
        "value": "DE"
    },
    {
        "text": "District of Columbia",
        "value": "DC"
    },
    {
        "text": "Florida",
        "value": "FL"
    },
    {
        "text": "Georgia",
        "value": "GA"
    },
    {
        "text": "Guam",
        "value": "GU"
    },
    {
        "text": "Hawaii",
        "value": "HI"
    },
    {
        "text": "Idaho",
        "value": "ID"
    },
    {
        "text": "Illinois",
        "value": "IL"
    },
    {
        "text": "Indiana",
        "value": "IN"
    },
    {
        "text": "Iowa",
        "value": "IA"
    },
    {
        "text": "Kansas",
        "value": "KS"
    },
    {
        "text": "Kentucky",
        "value": "KY"
    },
    {
        "text": "Louisiana",
        "value": "LA"
    },
    {
        "text": "Maine",
        "value": "ME"
    },
    {
        "text": "Maryland",
        "value": "MD"
    },
    {
        "text": "Massachusetts",
        "value": "MA"
    },
    {
        "text": "Michigan",
        "value": "MI"
    },
    {
        "text": "Minnesota",
        "value": "MN"
    },
    {
        "text": "Mississippi",
        "value": "MS"
    },
    {
        "text": "Missouri",
        "value": "MO"
    },
    {
        "text": "Montana",
        "value": "MT"
    },
    {
        "text": "Nebraska",
        "value": "NE"
    },
    {
        "text": "Nevada",
        "value": "NV"
    },
    {
        "text": "New Hampshire",
        "value": "NH"
    },
    {
        "text": "New Jersey",
        "value": "NJ"
    },
    {
        "text": "New Mexico",
        "value": "NM"
    },
    {
        "text": "New York",
        "value": "NY"
    },
    {
        "text": "North Carolina",
        "value": "NC"
    },
    {
        "text": "North Dakota",
        "value": "ND"
    },
    {
        "text": "Northern Mariana Islands",
        "value": "MP"
    },
    {
        "text": "Ohio",
        "value": "OH"
    },
    {
        "text": "Oklahoma",
        "value": "OK"
    },
    {
        "text": "Oregon",
        "value": "OR"
    },
    {
        "text": "Pennsylvania",
        "value": "PA"
    },
    {
        "text": "Puerto Rico",
        "value": "PR"
    },
    {
        "text": "Rhode Island",
        "value": "RI"
    },
    {
        "text": "South Carolina",
        "value": "SC"
    },
    {
        "text": "South Dakota",
        "value": "SD"
    },
    {
        "text": "Tennessee",
        "value": "TN"
    },
    {
        "text": "Texas",
        "value": "TX"
    },
    {
        "text": "Trust Territories",
        "value": "TT"
    },
    {
        "text": "Utah",
        "value": "UT"
    },
    {
        "text": "Vermont",
        "value": "VT"
    },
    {
        "text": "Virgin Islands",
        "value": "VI"
    },
    {
        "text": "Virginia",
        "value": "VA"
    },
    {
        "text": "Washington",
        "value": "WA"
    },
    {
        "text": "West Virginia",
        "value": "WV"
    },
    {
        "text": "Wisconsin",
        "value": "WI"
    },
    {
        "text": "Wyoming",
        "value": "WY"
    }
  ]

  const style = {
    label: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#272727"
    }
  }

  return (
    <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>
      <label htmlFor="state" style={style.label}>State</label>
      <OptionSelect id="state" variant="primary" options={options} value={value} onChange={onChange} placeholder="Select" />
    </div>
  )
}

function ShippingAddressInput({onChange=(state) => {}}) {
  const {mobile} = useResponsive()

  const style = {
    root: {
      display: "grid",
      gridTemplateColumns: mobile ? "1fr" : "1fr 1fr",
      gridRowGap: "16px",
      gridColumnGap: "16px"
    },
    full: mobile ? undefined : {gridColumnStart: 1, gridColumnEnd: 3}
  }

  let initState = {
    firstname: "",
    lastname: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zip: ""
  }

  if (DEBUG) {
    initState = {
      firstname: "charlie",
      lastname: "brown",
      line1: "123 street",
      line2: "",
      city: "Houston",
      state: "TX",
      zip: "12345"
    }
  }

  const checkZipCode = s => {
    const re = /^[0-9]{5}$/
    return re.test(s)
  }

  const [state, setState] = useState(initState)

  let complete = state.firstname && state.lastname && state.line1 && state.city && state.state && checkZipCode(state.zip)

  const update = (k, v) => {
    setState(s => ({...s, [k]: v}))
  }

  useEffect(() => {
    onChange({...state, complete})
  }, [state])

  const invalidZipErr = "Your postal code is incomplete."

  const handleZipChange = (e) => {
    const next = e.target.value
    if (next.length > 5) {
      return
    }
    update("zip", e.target.value)
  }

  return (
    <div style={style.root}>
      <TextfieldWithLabel id="firstname" label="First name" onChange={e => update("firstname", e.target.value)} value={state.firstname} />
      <TextfieldWithLabel id="lastname" label="Last name" onChange={e => update("lastname", e.target.value)} value={state.lastname} />
      <div style={style.full}>
        <TextfieldWithLabel id="line1" label="Address line 1" onChange={e => update("line1", e.target.value)} value={state.line1} />
      </div>
      <div style={style.full}>
        <TextfieldWithLabel id="line2" label="Address line 2" onChange={e => update("line2", e.target.value)} value={state.line2} />
      </div>
      <TextfieldWithLabel id="city" label="City" onChange={e => update("city", e.target.value)} value={state.city} />
      <StateSelect onChange={(value) => update("state", value)} value={state.state} />
      <TextfieldWithLabel err={!checkZipCode(state.zip) ? invalidZipErr : undefined} id="zip" label="Zip / Postal code" onChange={handleZipChange} value={state.zip} />
    </div>
  )
}

function TextfieldWithLabel({id, label, err, onChange, value}) {
  const [touched, setTouched] = useState(false)

  const style = {
    label: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#272727"
    },
    textfield: {
      root: {width: "initial"},
      input: {}
    }
  }

  if (touched && err) {
    style.textfield.input.border = "solid 2px #ED0000"
  }

  return (
    <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>
      <label htmlFor={id} style={style.label}>{label}</label>
      <Textfield id={id} variant="primary" onChange={onChange} value={value} style={style.textfield} onBlur={() => setTouched(true)} />
      {(touched && err) && (
        <span style={{color: "#ED0000", fontSize: "16px"}}>{err}</span>
      )}
    </div>
  )
}

function Card({header, children, style, editable=false, onEdit=() => {}}) {
  style = {
    background: "#FEFEFE",
    boxShadow: "1px 1px 4px rgba(83, 66, 153, 0.12)",
    borderRadius: "10px",
    padding: "16px",
    ...style
  }
  return (
    <div style={style}>
      <div style={{display: "flex"}}>
        <div style={{flex: "1"}}>
          <h2 style={{fontSize: "18px", lineHeight: "24px", color: "#555555", margin: "0 0 8px"}} className="secondary" tabIndex="1">{header}</h2>
        </div>
        <div style={{flex: "0", color: "rgba(83, 66, 153, 1)", fontSize: "16px", lineHeight: "18px", visibility: editable ? "visible" : "hidden"}}>
          <a href="#" onClick={(e) => {
            e.preventDefault()
            onEdit()
          }}>Edit</a>
        </div>
      </div>
      {children}
    </div>
  )
}

function CardText({children, style}) {
  return <p style={{fontSize: "16px", lineHeight: "26px", "color": "#555555", margin: 0, ...style}}>{children}</p>
}

function CardSpan({children, style}) {
  return <p style={{fontSize: "16px", lineHeight: "26px", "color": "#555555", margin: 0, ...style}}>{children}</p>
}

function clamp(min, v, max) {
  if (v < min) {
    return min
  }
  if (v > max) {
    return max
  }
  return v
}

function Title({children, style}) {
  return <AssessmentScreenTitle text={children} style={{padding: 0, ...style}}/>
}

function SelectProduct({products, value, onSelect}) {
  const options = products.map(p => ({value: p.id, title: p.description, rightText: `$${p.price / 100}`}))

  return (
    <div>
      <Title>Select Product</Title>
      <RadioList id="select-product" options={options} value={value.id || ''} onSelect={onSelect}/>
    </div>
  )
}

function ShippingAddress({onChange}) {
  const handleChange = e => {

    let address = {complete: e.complete}
    if (e.complete) {
      address.firstName =  e.firstname
      address.lastName =  e.lastname
      address.line1 =  e.line1
      address.line2 =  e.line2
      address.city =  e.city
      address.state =  e.state
      address.postal_code =  e.zip
      address.country =  "US"
    }
    onChange(address)
  }

  return (
    <div>
      <Title>Shipping Address</Title>
      <ShippingAddressInput onChange={handleChange} />
    </div>
  )
}

function ShippingMethod({value, onSelect}) {
  const options = [
    {
      value: "3to5",
      title: "Ground 3-5 business days",
      text: "Arrive by Dec 4-6 at 10PM", // TODO get estimate from somewhere
      "rightText": "$5.00"
    }
  ]

  return (
    <div>
      <Title>Shipping Method</Title>
      <RadioList id="select-shipping-method" options={options} value={value} onSelect={onSelect}/>
    </div>
  )
}

function PaymentMethod({value, onSelect}) {
  const options = [
    {value: "card", title: "Card"}
  ]

  return (
    <div>
      <Title>Payment Method</Title>
      <RadioList id="select-payment-method" options={options} value={value} onSelect={onSelect}/>
    </div>
  )
}

function PaymentInfo({onSubmit, onCompleteChange, address, paymentAddressSame, onCheck, billingAddress, onChangeBillingAddress}) {
  const style = {
    checkbox: {
      label: {
        padding: 0
      },
      checkboxContainer: {
        margin: 0,
        marginRight: "12px"
      },
      text: {
        color: "#272727"
      }
    }
  }

  // todo - since we sometimes set address field to never, we need to explicitly pass that info in when we create the payment intent

  const stripeOptions = {
    style: {
      base: {
        fontSize: "16px",
        lineHeight: "24px",
        color: "#272727",
        "::placeholder": {
          color: "transparent"
        }
      }
    }
  }

  const [state, setState] = useState({cardNumber: {}, cardExpiry: {}, cardCvc: {}})

  const handleChange = (e, p) => {
    setState(s => ({...s, [p]: e}))
  }

  let complete = state.cardNumber.complete && state.cardExpiry.complete && state.cardCvc.complete && (paymentAddressSame || billingAddress.complete)

  useEffect(() => {
    onCompleteChange(complete)
  }, [complete])

  const labelStyle = {
    display: "block",
    marginBottom: "4px"
  }

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridRowGap: "16px",
    gridColumnGap: "16px",
    alignItems: "end"
  }

  const h2Style = {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "600git",
    marginTop: 0
  }

  return (
    <div>
      <Title style={{marginBottom: 0}}>Payment Info</Title>
      <div style={{margin: "8px 0px"}}>
        <Checkbox 
          text="Billing address is the same as shipping address"
          value="same"
          variant="primary"
          onCheck={onCheck}
          checked={paymentAddressSame}
          reversed={true}
          style={style.checkbox}/>
      </div>
      {!paymentAddressSame && (
          <div style={{marginBottom: "32px"}}>
            <h2 style={h2Style}>Billing Address</h2>
            <ShippingAddressInput onChange={onChangeBillingAddress}/>
          </div>
        )
      }
      <div>
        <h2 style={h2Style}>Payment Details</h2>
        <div style={gridStyle}>
          <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
            <label htmlFor="cardnumber" style={labelStyle}>Card number</label>
            <CardNumberElement id="cardnumber" onChange={() => {}} className="stripe-text-input" options={stripeOptions} onChange={e => handleChange(e, 'cardNumber')} />
          </div>

          <div>
            <label htmlFor="expiry" style={labelStyle}>Expiration (MM/YY)</label>
            <CardExpiryElement id="expiry" onChange={() => {}} className="stripe-text-input" options={stripeOptions} onChange={e => handleChange(e, 'cardExpiry')} />
          </div>
          
          <div>
            <label htmlFor="cvc" style={labelStyle}>CVC</label>
            <CardCvcElement id="cvc" onChange={() => {}} className="stripe-text-input" options={stripeOptions} onChange={e => handleChange(e, 'cardCvc')} />
          </div>
        </div>
      </div>

      {/* <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button disabled={!stripe}>Submit</button>
      </form> */}
    </div>
  )
}

function Dollars({cents}) {
  return `$${cents / 100}`
}

function CreditCard({src}) {
  return (
    <div style={{width: "52px", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <img src={src} alt=""  style={{width: "100%", height: "auto"}}/>
    </div>
  )
}

function ViewOrder({productName, subtotal, shipping, tax, amount, firstName, lastName, line1, line2,
  city, state, postal_code, country, brand, last4, editable=false, onPurchase, goto}) {

  const {mobile, tablet, desktop} = useResponsive()

  const cards = {
    visa: images['card-visa'],
    amex: images['card-amex'],
    mastercard: images['card-mastercard'],
    discover: images['card-discover'],
  }

  const card = cards[brand] ? <CreditCard src={cards[brand]} /> : null

  return (
    <div style={{display: "flex", gap: "8px", flexDirection: mobile || tablet ? "column" : "row" }}>
      <div style={{flex: "auto", display: "flex", flexDirection: "column", gap: "8px", minWidth: "250px"}}>
        <Card header="Shipping Address" editable={editable} onEdit={() => goto("address")}>
          <CardText>{firstName} {lastName}</CardText>
          <CardText>{line1}</CardText>
          {line2 && <CardText>{line2}</CardText>}
          <CardText>{city}, {state} {postal_code}</CardText>
        </Card>
        <Card header="Shipping Method" editable={editable} onEdit={() => goto("shipping_method")}>
          <CardText>Delivery 3-5 business days</CardText>
          <CardText>Arrives by Dec 1, 2022</CardText>
        </Card>
        <Card header="Payment Method" editable={editable} onEdit={() => goto("payment")}>
          <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
            {card}
            <CardText>{card ? '' : `${brand} `}Ending in {last4}</CardText>            
          </div>
        </Card>
      </div>
      <div style={{flex: "auto"}}>
        <Card header="Order Summary" style={{height: "100%"}} editable={editable} onEdit={() => goto("product")}>
          <OrderSummary productName={productName} subtotal={subtotal} shipping={shipping} tax={tax} amount={amount} postalCode={postal_code} />
        </Card>
      </div>
    </div>
  )
}

function OrderSummary({productName, subtotal, shipping, tax, amount, postalCode}) {
  const style = {
    li: {
      marginTop: "4px"
    },
    rowItem: {
      display: "flex", justifyContent: "space-between"
    },
    total: {
      fontSize: "28px", lineHeight: "38px", color: "#555555", fontWeight: "bold"
    },
    price: {
      fontWeight: "bold"
    }
  }

  return (
    <div>
      <ul style={{margin: 0, padding: 0, listStyleType: "none"}}>
        <li style={{...style.li, marginBottom: "24px"}}>
          <div style={style.rowItem}>
            <CardSpan>{productName}</CardSpan>
            <CardSpan style={style.price}><Dollars cents={subtotal} /></CardSpan>
          </div>
        </li>
        <li style={style.li}>
          <div style={style.rowItem}>
            <CardSpan>Subtotal (1 item)</CardSpan>
            <CardSpan style={style.price}><Dollars cents={subtotal} /></CardSpan>
          </div>
        </li>
        <li style={style.li}>
          <div style={style.rowItem}>
            <CardSpan>Shipping</CardSpan>
            <CardSpan style={style.price}><Dollars cents={shipping} /></CardSpan>
          </div>
        </li>
        <li style={style.li}>
          <div style={style.rowItem}>
            <div style={{display: "flex", flexDirection: "column"}}>
              <CardSpan>Estimated Tax</CardSpan>
              <CardSpan>(Tax for {postalCode})</CardSpan>
            </div>
            <CardSpan style={style.price}><Dollars cents={tax} /></CardSpan>
          </div>
        </li>
        <li>
          <hr/>
          <div style={{...style.rowItem, ...style.total}}>
            <span>Total</span>
            <span><Dollars cents={amount} /></span>
          </div>
        </li>
      </ul>
    </div>
  )
}

function PreviewOrder({productName, cost, address, paymentMethodDetails, goto}) {
  const {subtotal, shipping, tax, amount} = cost
  const {firstName, lastName, line1, line2, city, state, postal_code, country} = address
  const {brand, last4} = paymentMethodDetails
  
  return (
    <div>
      <Title>Preview Order</Title>
      <ViewOrder 
        productName={productName}
        goto={goto}
        editable={true}
        subtotal={subtotal}
        shipping={shipping}
        tax={tax}
        amount={amount}
        firstName={firstName}
        lastName={lastName}
        line1={line1}
        line2={line2}
        city={city}
        state={state}
        postal_code={postal_code}
        country={country}
        brand={brand}
        last4={last4}/>
    </div>
  )
}

function OrderConfirmation({productName, cost, address, paymentMethodDetails}) {
  const {subtotal, shipping, tax, amount} = cost
  const {firstName, lastName, line1, line2, city, state, postal_code, country} = address
  const {brand, last4} = paymentMethodDetails
  
  return (
    <div>
      <Title>Order Confirmation</Title>
      <p>You have successfully placed your order. Your order number is <b>abc-12345.</b></p>
      <ViewOrder 
        productName={productName}
        goto={() => {}}
        editable={false}
        subtotal={subtotal}
        shipping={shipping}
        tax={tax}
        amount={amount}
        firstName={firstName}
        lastName={lastName}
        line1={line1}
        line2={line2}
        city={city}
        state={state}
        postal_code={postal_code}
        country={country}
        brand={brand}
        last4={last4}/>
    </div>
  )
}

const BUTTON_STATES = {
  HIDDEN: 'HIDDEN',
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
  LOADING: 'LOADING'
}

export default function Checkout() {
  const [customerId, setCustomerId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  usePrompt(CheckoutPrompt)

  useEffect(() => {
    checkoutClient.createSetupIntent({})
      .then(resp => {
        setCustomerId(resp.customerId)
        setClientSecret(resp.clientSecret)
      })
  }, [])

  const graphikNormal = {
    family: "Graphik",
    src: 'url("./fonts/Graphik-Regular.otf") format("opentype")',
    weight: "normal",
    style: "normal",
  }


  const options = {
    clientSecret,
    appearance: {
      variables: {
        colorBackground: '#FFFFFF',
        colorPrimary: "#534299",
        colorText: '#272727',
        colorDanger: '#ED0000',
        spacingUnit: "7px",
      },
      rules: {
        ".Input::placeholder": {
          color: "transparent"
        }
      }
    },
    fonts: [graphikNormal]
  }

  return (clientSecret && customerId) ? (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutInner customerId={customerId} clientSecret={clientSecret} />
    </Elements>
  ) : <LayoutScreen showProfile={true} />

}

function CheckoutInner({customerId, clientSecret}) {
  const [index, setIndex] = useState(0)
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState({})
  const [paymentMethod, setPaymentMethod] = useState('')
  const [address, setAddress] = useState({complete: false})
  const [shippingMethod, setShippingMethod] = useState('')
  const [paymentAddressSame, setPaymentAddressSame] = useState(true)
  const [paymentMethodSelect, setPaymentMethodSelect] = useState('')
  const [paymentIntentSecret, setPaymentIntentSecret] = useState('')
  const [paymentIntentStatus, setPaymentIntentStatus] = useState('')
  const [cost, setCost] = useState({loaded: false, subtotal: 0, shipping: 0, tax: 0, amount: 0})
  const [paymentFormComplete, setPaymentFormComplete] = useState(false)
  const [paymentMethodDetails, setPaymentMethodDetails] = useState({loaded: false})
  const [mode, setMode] = useState('NORMAL')

  const [billingAddress, setBillingAddress] = useState({})

  useEffect(async () => {
    if (paymentMethod) {
      const {id, last4, brand} = await checkoutClient.getPaymentMethod({id: paymentMethod})
      setPaymentMethodDetails({loaded: true, id, last4, brand})
    }
  }, [paymentMethod])

  const stripe = useStripe()
  const elements = useElements()

  const createPaymentIntent = async (customerId, paymentMethod, productId) => {
    const resp = await checkoutClient.createPaymentIntent({customerId, paymentMethod, productId})
    const {clientSecret, subtotal, shipping, tax, amount} = resp
    setPaymentIntentSecret(clientSecret)
    setCost({loaded: true, subtotal, shipping, tax, amount})
  }

  const handleSelectProduct = (id) => {
    const found = products.find(p => p.id === id)
    if (found) {
      setProduct(found)
    }
  }

  const goto = id => {
    const index = screens.findIndex(s => s.id === id)
    if (index >= 0) {
      setIndex(index)
    }
  }

  const edit = id => {
    setMode('EDIT')
    goto(id)
  }

  const screens = [
    {
      id: "product",
      jsx: <SelectProduct key="products" products={products} value={product} onSelect={handleSelectProduct} />
    },
    {
      id: "address",
      jsx: <ShippingAddress key="address" onChange={setAddress} />,
    },
    {
      id: "shipping_method",
      jsx: <ShippingMethod key="shipping_method" value={shippingMethod} onSelect={setShippingMethod} />,
    },
    {
      id: "payment_method",
      jsx: <PaymentMethod key="payment_method" value={paymentMethodSelect} onSelect={setPaymentMethodSelect} />,
    },
    {
      id: "payment",
      jsx: (
        <PaymentInfo
          key="payment"
          address={address}
          paymentAddressSame={paymentAddressSame}
          onCheck={(v) => setPaymentAddressSame(v)}
          onSubmit={setupIntent => setPaymentMethod(setupIntent.payment_method)}
          onCompleteChange={setPaymentFormComplete}
          billingAddress={billingAddress}
          onChangeBillingAddress={setBillingAddress}/>
      )
    },
    {
      id: "preview",
      jsx: <PreviewOrder key="preview" productName={product.description} cost={cost} address={address} paymentMethodDetails={paymentMethodDetails} goto={edit} />,
    },
    {
      id: "confirmation",
      jsx: <OrderConfirmation key="confirmation" productName={product.description} cost={cost} address={address} paymentMethodDetails={paymentMethodDetails} />
    }
  ]

  const cur = screens[index].id

  const ready = {
    product: !!product.id,
    address: address.complete,
    shipping_method: !!shippingMethod,
    payment_method: !!paymentMethodSelect,
    payment: paymentFormComplete,
    preview: cost.loaded,
  }

  useEffect(() => {
    console.log('ready:', ready)
    if (ready[cur]) {
      setNextButtonState(BUTTON_STATES.ENABLED)
    } else {
      setNextButtonState(BUTTON_STATES.DISABLED)
    }
  }, [cur, ...Object.values(ready)]) // TODO is this allowed

  // useEffect(() => {
  //   if (cur === "preview") {
  //     if (!customerId || !paymentMethod || !product.id) {
  //       return
  //     }
  //     checkoutClient.createPaymentIntent({customerId, paymentMethod, productId: product.id})
  //       .then(resp => {
  //         const {clientSecret, subtotal, shipping, tax, amount} = resp
  //         setPaymentIntentSecret(clientSecret)
  //         setCost({loaded: true, subtotal, shipping, tax, amount})
  //       })
  //   }
  // }, [index])

  useEffect(() => {
    checkoutClient.getProducts({})
      .then(resp => {
        setProducts(resp.products)
      })
  }, [])

  useEffect(() => {
    if (products.length > 0 && !product) {
      setProduct(products[0])
    }
  }, [products])

  useEffect(() => {
    if (address) {
      console.log('address set', address)
    }
  }, [address])

  // const screens = [
  //   <SelectProduct key="products" products={products} value={product} onSelect={handleSelectProduct} />,
  //   // <ShippingAddress onChange={setAddress} />,
  //   // <ShippingMethod value={shippingMethod} onSelect={onSelectShippingMethod} />,
  //   // <PaymentMethod value={paymentMethod} onSelect={onSelectPaymentMethod} />,
  //   <PaymentInfo key="payment" onSubmit={setupIntent => setPaymentMethod(setupIntent.payment_method)} />,
  //   // <PreviewOrder order={order} customerId={customerId} paymentMethod={paymentMethod}/>,
  //   <PreviewOrder key="preview" customerId={customerId} paymentMethod={paymentMethod} productId={product.id} onConfirm={onConfirm} />,
  //   <OrderConfirmation2 key="confirmation" />
  //   // <OrderConfirmation order={order} />
  // ]

  const [backButtonState, setBackButtonState] = useState(BUTTON_STATES.ENABLED)
  const [nextButtonState, setNextButtonState] = useState(BUTTON_STATES.DISABLED)

  useEffect(() => {
    if (cur === "preview") {
      setBackButtonState(BUTTON_STATES.HIDDEN)
    }

    if (cur === "confirmation") {
      setBackButtonState(BUTTON_STATES.HIDDEN)
      setNextButtonState(BUTTON_STATES.HIDDEN)
    }
  }, [cur])

  const previewIndex = screens.findIndex(s => s.id === "preview")

  const getNextIndex = () => {
    if (mode === "NORMAL") {
      return clamp(0, index+1, screens-1)
    }
    if (mode === "EDIT") {
      if (cur === "preview") {
        return clamp(0, index+1, screens-1)
      }
      return previewIndex
    }
  }

  const nextIndex = getNextIndex()

  // handle when submitting product selection screen
  const submitProduct = async () => {
    if (product.id) {
      if (mode === "EDIT") {
        await createPaymentIntent(customerId, paymentMethod, product.id)
      }
      setIndex(nextIndex)
    }
  }

  // handle when submitting address screen
  const submitAddress = () => {
    if (address.complete) {
      setIndex(nextIndex)
    }
  }

  // handle when submitting shipping method screen
  const submitShippingMethod = () => {
    if (shippingMethod) {
      setIndex(nextIndex)
    }
  }

  // handle when submitting payment method screen
  const submitPaymentMethodSelect = () => {
    if (paymentMethodSelect) {
      setIndex(nextIndex)
    }
  }

  // handle when submitting payment screen
  const submitPayment = async () => {
    if (!stripe || !elements) {
      return
    }

    // TODO/TEMP/HACK, skip confirm if payment method already exists
    // re-confirming setup was causing error so just skipping for now
    if (paymentMethod) {
      setIndex(nextIndex)
      return
    }

    let _address
    if (paymentAddressSame) {
      _address = address
    } else {
      _address = billingAddress
      _address.postal_code = _address.zip
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            city: _address.city,
            country: "US",
            line1: _address.line1,
            line2: _address.line2,
            postal_code: _address.postal_code,
            state: _address.state
          }
        },
      },
      return_url: window.location.origin + window.location.pathname
    })

    if (result.error) {
      // TODO handle error
      alert('error')
      console.log('confirmSetup error', result.error)
      return
    }
    if (result.setupIntent) {
      const paymentMethod = result.setupIntent.payment_method
      setPaymentMethod(paymentMethod)
      await createPaymentIntent(customerId, paymentMethod, product.id)
      setIndex(nextIndex)
      return
    }
  }

  // handle when submitting preview screen
  const submitPreview = async () => {
    if (!stripe || !paymentIntentSecret || !paymentMethod) {
      return
    }
    const result = await stripe.confirmCardPayment(paymentIntentSecret, {payment_method: paymentMethod})
    if (result.error) {
      // TODO handle error
      alert('error')
      return
    }
    if (result.paymentIntent) {
      // TODO: fixme
      await checkoutClient.placeOrder({})
      setPaymentIntentStatus(result.paymentIntent.status)
      setIndex(nextIndex)
      return
    }
  }

  const nextHandlers = {
    async product() {
      await submitProduct()
    },
    async address() {
      await submitAddress()
    },
    async shipping_method() {
      await submitShippingMethod()
    },
    async payment_method() {
      await submitPaymentMethodSelect()
    },
    async payment() {
      await submitPayment()
    },
    async preview() {
      await submitPreview()
    }
  }

  const back = () => {
    if (index === 0) {
      skipPrompt()
      history.go(-1)
      return
    }
    if (mode === "EDIT") {
      setIndex(previewIndex)
      return
    }
    setIndex(index => clamp(0, index-1, screens-1))
  }

  const next = async () => {
    setNextButtonState(BUTTON_STATES.LOADING)
    const handler = nextHandlers[cur]
    await handler()
    setNextButtonState(BUTTON_STATES.ENABLED)
  }

  const nextText = {
    payment: "Preview Order",
    preview: "Purchase"
  }

  const {mobile, tablet, desktop} = useResponsive()
  const disclaimer = (
    <div style={{display: "flex", justifyContent: "center", marginTop: "12px"}}>
      <p style={{color: "rgba(124, 124, 124, 1)", fontSize: "12px", lineHeight: "18px", maxWidth: mobile ? "255px" : undefined, textAlign: "center"}}>
        By placing an order, you agree to Zena’s terms and privacy policy.
      </p>
    </div>
  )

  const progressSteps = [
    "Select Product",
    "Shipping Address",
    "Shipping Method",
    "Payment Method",
    "Preview Order",
    "Confirmation"
  ]

  return (
    <div style={{flex: "auto", display: "flex", flexDirection: "column"}}>
    {(mobile || tablet) && <SmoothProgress progress={Math.ceil((index / (screens.length - 1)) * 100)} />}
    <LayoutScreen showProfile={true}>
      <div style={{maxWidth: "650px"}}>
        {
          desktop && (
            <div style={{marginBottom: "64px"}}>
              <AdjustedProgress cur={index} steps={progressSteps} />
            </div>
          )
        }
        <div style={{maxWidth: "600px", marginBottom: "48px"}}>
          <div style={{padding: "0 24px"}}>
            <Carousel cur={index}>
            {screens.map(s => s.jsx)}
            </Carousel>
            <div style={{paddingTop: "48px"}}>
              <ButtonContainer>
                <Button onClick={back} outline={true} state={backButtonState}>Back</Button>
                <Button onClick={next} state={nextButtonState}>{nextText[cur] || "Next"}</Button>
              </ButtonContainer>
              {cur === "preview" && disclaimer}
            </div> 
          </div>
        </div>
      </div>
    </LayoutScreen>
    </div>
  )
}

// function withAdjustedProgress(ProgressBar) {
//   return ({cur, steps}) => {
//     // HACK: this is to map the current screen index to the corresponding progress bar step that should be shown
//     // (they don't map 1 to 1)
//     const m = {
//       0: 0,
//       1: 1,
//       2: 2,
//       3: 3,
//       4: 3,
//       5: 4,
//       6: 7
//     }
//     cur = m[cur]
//     return <ProgressBar cur={cur} steps={steps} />
//   }
// }

// const AdjustedProgress = withAdjustedProgress(Progress)
// const AdjustedMobileProgress = withAdjustedProgress(MobileProgress)

function AdjustedProgress({cur, steps}) {
  // HACK: this is to map the current screen index to the corresponding progress bar step that should be shown
  // (they don't map 1 to 1)
  const m = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 3,
    5: 4,
    6: 7
  }
  cur = m[cur]
  return <Progress cur={cur} steps={steps} /> 
}

function ButtonContainer({children}) {
  return (
    <div style={{display: "flex", justifyContent: "center"}}>
      <div style={{display: "flex", justifyContent: "space-between", gap: "32px", width: "100%", maxWidth: "400px"}}>
        {children}
      </div>
    </div>
  )
}

function Button({onClick, children, state, outline=false}) {
  const className = `btn primary ${outline ? 'outline' : ''}`

  if (state === BUTTON_STATES.HIDDEN) {
    return null
  }

  let disabled = state === BUTTON_STATES.DISABLED

  if (state === BUTTON_STATES.LOADING) {
    disabled = true
    children = 'loading...'
  }
 
  return (
    <button className={className} onClick={onClick} disabled={disabled} style={{padding: 0}}>{children}</button>
  )
}

function Carousel({children=[], cur=0}) {
  return (
    <div>
      {
        children.map((c, i) => (
          <div key={i} style={{display: i === cur ? undefined : "none"}}>{c}</div>
        ))
      }
    </div>
  )
}
