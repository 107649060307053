import "../css/QRCodePage.css"
import images from "../img"
import NavBar from '../components/NavBar'
import Modal from 'react-modal';
import { Auth } from '../components/Auth'
import {useState, useEffect} from 'react'
import { usePrompt, AssessmentPrompt } from '../prompt'

function QR() {
    return (
        <img src={images["sample-qr"]} alt="" />
    )
}

function LoginModal({isOpen, close, onContinue}) {
    const style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "56px",
        width: "clamp(200px, 100%, 500px)",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "24px",
        borderRadius: "28px",
        border: "none",
        backgroundColor: "transparent",
        color: "rgb(14, 83, 64)",
        cursor: "pointer",
        transition: "box-shadow 150ms ease",
      }

      return (
        <Modal
        className="modal"
        overlayClassName="overlay"
        isOpen={isOpen}
        onRequestClose={() => close()}
      >
        <h1 className="secondary" style={{paddingLeft: "0px"}}>Log In</h1>
        <Auth onContinue={onContinue} showGuestLogin={false}/>
        <button style={style} onClick={close}>
          <span>Cancel</span>
        </button>
      </Modal>
      )
}

export default function QRCodePage() {
    const [showAuth, setShowAuth] = useState(false)
    usePrompt(AssessmentPrompt)

    const handleClick = e => {
        e.preventDefault()
        setShowAuth(true)
    }

    const [authed, setAuthed] = useState(sessionStorage.getItem("authed"))

    const handleAuthSuccess = () => {
      setShowAuth(false)
      setAuthed(true)
    }

    return (
        <main className="qr-page">
            <NavBar variant="secondary" isAlt={false} profile="profile-white"/>
            <LoginModal isOpen={showAuth} onContinue={handleAuthSuccess} close={() => setShowAuth(false)} />
            <div className="main-content" style={{marginBottom: "64px"}}>
                <div style={{width: "100%", maxWidth: "500px"}}>
                    <h2 style={{textAlign: "center"}}>Show QR code to cashier</h2>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div className="qr-container" style={{width: "100%", maxWidth: "350px"}}>
                            <QR />
                        </div>
                    </div>
                    <h1>Buy Zena Today</h1>
                    <p>When you are ready to pay, show this QR code to the cashier to pick up your packages and complete your order.</p>
                    { !authed && (
                      <>
                        <h2 style={{marginBottom: 0}}>Not ready to check out?</h2>
                        <a href="#" onClick={handleClick}>Create an account to save QR code</a>
                      </>
                    )}
                </div>
            </div>
        </main>
    )
}
