import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import apple from '../icn/apple.png'
import google from '../icn/g-logo.png'
import { ReactComponent as ProfileIcon } from '../icn/profile.svg'
import { subscribe } from '../subscribers'
import { authClient, client } from '../client'
import config from '../config'
import store from '../store'

const loaded = {}

function load(src, cb) {
  if (loaded[src]) {
    cb()
    return
  }

  loaded[src] = true

  const script = document.createElement('script')
  script.src = src
  script.onload = () => {
    if (cb) cb()
  }

  document.body.appendChild(script)
}

let didAppleInit = false

function makeAppleInit(config) {
  function initApple() {
    if (!config.APPLE_CLIENT_ID) {
      return
    }

    if (!didAppleInit) {
      didAppleInit = true
      window.AppleID.auth.init({
        clientId: config.APPLE_CLIENT_ID,
        scope : 'name email',
        redirectURI : `${window.location.origin}/auth/apple/success`,
        //state : '[STATE]',
        //nonce : '[NONCE]',
        usePopup : true,
      });
    } else {
      //window.AppleID.auth.renderButton()
    }
  }

  return initApple
}

function popup(target, name) {
  const q = function (e, t) {
    var i = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width,
      o = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height;
    return {
      left: i / 2 - e / 2 + window.screenLeft,
      top: o / 2 - t / 2 + window.screenTop,
    };
  };
  const top = q(700,700).top
  const left = q(700,700).left
  const features = `width=700,height=700,top=${top},left=${left},resizable=no,location=no,menubar=no`
  window.open(target, name, features)
}

export function googleAuth(mode, transfer=false) {
  store.dispatch({type: "GOOGLE_AUTH_START", mode, transfer})
  popup(buildGoogleRedirect(), "GoogleAuth")
  return subscribe("google")
  //popup("/oauth/google?start=1", "GoogleAuth")
}

export function appleAuth(mode, transfer=false) {
  store.dispatch({type: "APPLE_AUTH_START", mode, transfer})
  window.AppleID.auth.signIn()
  return subscribe("apple")
}

// function buildAppleRedirect() {
//   const params = {
//     scope: "",
//     //access_type: "offline",
//     redirect_uri: window.location.origin + "/oauth/apple",
//     response_type: "code",
//     response_mode: "query",
//     client_id: "care.dexter.qa",
//     //prompt: "consent",
//   }
//   const base = "https://appleid.apple.com/auth/authorize"
// 
//   const qs = Object.entries(params).map(([k,v]) => `${k}=${v}`).join("&")
//   return encodeURI(`${base}?${qs}`)
// }


function buildGoogleRedirect() {
  const params = {
    scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    access_type: "offline",
    redirect_uri: window.location.origin + "/oauth/google",
    response_type: "code",
    client_id: "984837488679-tolpr4vm31fkpftu3gjfddva7054fcnd.apps.googleusercontent.com",
    prompt: "consent",
  }
  const base = "https://accounts.google.com/o/oauth2/v2/auth"

  const qs = Object.entries(params).map(([k,v]) => `${k}=${v}`).join("&")
  return encodeURI(`${base}?${qs}`)
}

export function ImageButton({src, children, onClick, image}) {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 52px 0 12px",
    height: "56px",
    width: "clamp(200px, 100%, 500px)",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    border: "none",
    borderRadius: "28px",
    backgroundColor: "transparent",
    color: "rgba(99, 99, 99, 1)",
    cursor: "pointer",
    transition: "box-shadow 150ms ease",
    border: "1px solid rgba(117, 117, 117, 1)",
  }

  return (
    <button style={style} onClick={onClick}>
      { image || <img style={{width: "40px", padding: "4px"}} src={src}/> }
      <span style={{flex: "1 1 auto"}}>{children}</span>
    </button>
  )
}

export function Auth({ onSelect, onContinue, showGuestLogin=true}) {
  const dispatch = useDispatch()
  const config = useSelector(state => state.config)

  useEffect(() => {
    load("https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js", makeAppleInit(config))
    //load("https://accounts.google.com/gsi/client", initGoogle)
  }, [config])

  return (
    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
      <div style={{marginBottom: "20px"}}>
        <ImageButton src={google} onClick={() => {
          onSelect('google', async (mode) => {
            const ret = await googleAuth(mode)
            onContinue(ret)
          })
        }}>
          Continue with Google
        </ImageButton>
      </div>
      <div>
        <ImageButton src={apple} onClick={() => {
          onSelect('apple', async (mode) => {
            const ret = await appleAuth(mode)
            onContinue(ret)
          })
        }}>
          Continue with Apple
        </ImageButton>
      </div>
      { showGuestLogin && (
      <>
        <div style={{display: "flex", justifyContent: "center", fontWeight: "500", margin: "8px 0", color: "rgba(99, 99, 99, 1)"}}>Or</div>
        <div>
          <ImageButton image={<ProfileIcon width={40} fill="black"/>} onClick={() => {
            onSelect('guest', async () => {
              await authClient.guestLogin()
              onContinue({})
            })
          }}>
            Continue as a Guest
          </ImageButton>
        </div>
      </>
      )}
    </div>
  )
}

