import { useState, useEffect, createElement } from 'react'
import { APIAssessment } from '../components/Assessment'
import bg from '../img/bg.png'
import LayoutScreen from '../components/Layout'
import NavBar from '../components/NavBar'
import images from '../img'
import ProductSelect, { OrderSummary } from '../components/Product'
import SelectProduct from '../pages/SelectProduct.js'
import {RadioSelectList} from '../components/AssessmentComponents.js'
import Radio, {RadioGroup} from '../components/Radio'
import { ReactComponent as Exclamation } from '../icn/circle-exclamation.svg'

import {
  Assessment
} from '../components/Assessment'
import CheckoutPage from './CheckoutTmp.js'

import history from '../history'
import PrivacyRest from '../components/PrivacyRest'

const spec = {
  type: 'Assessment',
  id: 'A297BAC6-8B43-4A55-8F38-8781BC2E2A52',
  children: [
    {
      type: 'Screen',
      id: '8D4D5B2D-0C45-4F99-8F5D-C658AE7910D5',
      title: 'first screen',
      showContinueButton: true,
      children: [
        {
          type: 'MultiChoiceButtons',
          id: '0100AC68-A026-425E-A77E-39D7F45E7C77',
          question: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
          summary: 'A or B'
        },
        {
          type: 'TextfieldQuestion',
          id: 'C42E4A2A-5852-49D3-9284-BFA4C6DF7207',
          question: 'what is your first Name',
          summary: 'first Name'
        },
        {
          type: 'TextfieldQuestion',
          id: '1D610317-7B93-4C9A-A883-556356E3764C',
          question: 'what is your last Name',
          summary: 'last name'
        },
        {
          type: 'YesNoRadioQuestion',
          id: 'E69B46B8-2BBB-4CD8-90EA-BB76D673EE58',
          question: 'Lorem ipsum dolor sit amet?',
          summary: 'lorem x'
        },
        {
          type: 'YesNoRadioQuestion',
          id: '1D38BC88-F44A-49DD-AC8E-6BF11CD4D60C',
          question: 'Lorem ipsum dolor sit amet?',
          summary: 'lorem y'
        },
        {
          type: 'YesNoRadioQuestion',
          id: '416FFE2A-5EAF-4119-A5BA-817E4698B286',
          question: 'Lorem ipsum dolor sit amet?',
          summary: 'lorem z'
        }
      ]
    },
    {
      type: 'Screen',
      id: 'EAD84845-3D6D-46CC-9F26-11466F3657BB',
      title: 'second screen',
      showContinueButton: true,
      children: [
        {
          type: 'Paragraph',
          id: 'FF02EB9A-78F4-416F-B587-378E452BB149',
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
      ]
    }
  ]
}

// export default function Experimental({client}) {
//     //<APIAssessment assessmentId="7ddf8551-f417-417d-804b-69029397bddc" client={client}/>
//   // const style = {
//   //   height: "100%",
//   //   backgroundImage: `url(${bg})`,
//   //   backgroundRepeat: "no-repeat",
//   //   backgroundPosition: "top left",
//   //   backgroundColor: "red",
//   //   width: "20%",
//   //   // height: "100%",
//   //   backgroundSize: "cover",
//   //
//   // }
//   // return (
//   //   <div style={{flex: 1}}>
//   //     <div style={style}></div>
//   //   </div>
//   // )
//   const navbar = <NavBar variant="secondary" onBack={() => {}} isAlt={false} showProfile={true} />
//
//   return (
//     <LayoutScreen heroSrc={images.success} navbar={navbar}>
//       <div>
//         <h1 style={{padding: 0}}>hello</h1>
//         <p>this is content</p>
//       </div>
//     </LayoutScreen>
//   )
// }

// sets react context, radio will grab the name from the context to act as a radio group
// export function RadioGroup({children}) {
//   const context = ...
//   return (
//     <WithContext>
//       {children}
//     </WithContext>
//   )
// }
//
// <RadioGroup>
//   <Radio />
//   <Radio />
//   ...
// </RadioGroup>


export default function Experimental({client}) {
  // const navbar = <NavBar variant="secondary" onBack={() => {}} isAlt={false} showProfile={true} />

  // return (
  //   <div style={{margin: "16px", display: "flex", "flexDirection": "column", "gap": "48px"}}>
  //     <div>
  //       <ProductSelect />
  //     </div>
  //     <div>
  //       <OrderSummary quantity={1}/>
  //     </div>
  //   </div>
  // )
  // const [value, setValue] = useState('a')
  //
  // const options = [
  //   {title: "Zena • 1 blister pack", value: "one", text: "Combination birth control pills"},
  //   {title: "Zena • 1 blister pack", value: "two"}
  // ]
  // const props = {id: "product-select", options, value: "one", variant: "primary", onEvent: (e) => console.log(e)}
  //
  // return (
  //   <div>
  //     <div>
  //       <Radio name="one" value="a" checked={value === "a"} onChange={v => setValue(v)}/>
  //       a
  //     </div>
  //     <div>
  //       <label>
  //       <Radio name="one" value="b" checked={value === "b"} onChange={v => setValue(v)} labelled={true}/>
  //       b
  //       </label>
  //     </div>
  //   </div>
  // )
  // return <SelectProduct />
  // return (
  //   <div style={{backgroundColor: "red", flex: 1, display: "flex"}}>
  //     <div style={{width: "30%", backgroundColor: "green"}}>
  //       {/* <div style={{width: "100%", height: "100%", backgroundColor: "yellow"}}></div> */}
  //       <img src={images['select-pack-img']} alt="" style={{maxHeight: "100%"}}/>
  //     </div>
  //     <div style={{width: "70%", backgroundColor: "blue"}}></div>
  //   </div>
  // )

  // TODO tmp
  // const back = () => {
  //
  // }
  //
  // const next = back
  //
  // return (
  //   <LayoutScreen showProfile={true}>
  //     <div style={{maxWidth: "500px"}}>
  //       <div style={{padding: "0 32px"}}>
  //         <AssessmentScreenTitle text="Select a product" style={{paddingLeft: 0}}/>
  //         <ProductSelectList onSelect={p => console.log("selected:", p)} />
  //       </div>
  //       <div style={{display: "flex", justifyContent: "space-between", gap: "32px", padding: "48px 16px"}}>
  //         <button className="btn primary outline" onClick={back}>Back</button>
  //         <button className="btn primary" onClick={next}>Next</button>
  //       </div>
  //     </div>
  //   </LayoutScreen>
  // )
  // return (
  //   <CheckoutPage />
  // )

  // const screens = [
  //   <A a="a" />,
  //   <B b="b" />,
  //   <C c="c" />
  // ]

  // const [cur, setCur] = useState(0)

  // const back = () => {
  //   if (cur > 0) {
  //     setCur(cur => cur - 1)
  //   }
  // }

  // const next = () => {
  //   if (cur < screens.length-1) {
  //     setCur(cur => cur + 1)
  //   }
  // }

  // const elements = [
  //   <div>a</div>,
  //   <div>b</div>,
  //   <div>c</div>
  // ]

  // return (
  //   <div>{elements}</div>
  // )
  // const [object, setObject] = useState({loaded: false})

  // useEffect(() => {
  //   setTimeout(() => {
  //     setObject({loaded: true, name: 'joe'})
  //   }, 3000)
  // }, [])
  
  // const c = <Component object={object} />

  // return (
  //   <div>
  //     <button onClick={() => history.push('/qr')}>in store</button>
  //     <button onClick={() => history.push('/checkout')}>online</button>
  //   </div>
  // )
  const [cur, setCur] = useState(0)

  // const steps = [
  //   "Select Product",
  //   "Shipping Address",
  //   "Shipping Method",
  //   "Payment Method",
  //   "Preview Order",
  //   "Confirmation"
  // ]

  // return (
  //   <div>
  //     <div style={{width: "650px"}}>
  //       <Progress cur={cur} steps={steps} />
  //     </div>
  //     <button onClick={() => setCur(cur => cur - 1)}>prev</button>
  //     <button onClick={() => setCur(cur => cur + 1)}>next</button>
  //   </div>
  // )
  // const steps = [
  //   () => <PrivacyScreen />,
  //   () => <ConfirmNumbersScreen />
  // ]

  // // let seti = () => {console.error("seti not set")}
  // // const [seti, setSeti] = useState(() => {console.error("seti not set")})

  // const [show, setShow] = useState(true)
  // const [increment, setIncrement] = useState(0)

  // return (
  //   <div>
  //     {increment}
  //     {show && <Stepper steps={steps} />}
  //     <button onClick={(e) => setShow(s => !s)}>switch</button>
  //     <button onClick={(e) => seti(0)}>reset</button>
  //     <button onClick={(e) => setIncrement(i => i+1)}>increment</button>
  //   </div>
  // )

  const [f, setf] = useState(undefined)
  const [showA, setShowA] = useState(false)

  return (
    <div style={{background: "black", padding: "16px"}}>
      <Exclamation />
    </div>
  )
}

function RegisterA({register}) {
  const [state, _] = useState({a: 'somestate'})
  const f = () => console.log('a state', state)
  useEffect(() => {
    register(f)
  }, [])
  return 'A'
}

function RegisterB({register}) {
  const f = () => console.log('b')
  useEffect(() => {
    register(f)
  }, [])
  return 'B'
}


function Stepper({steps=[], shownav=true, onBack=(goBack) => {goBack()}, onNext=(goNext) => {goNext()}, buttonText={back: 'back', next: 'next'}}) {
  const [i, seti] = useState(0)

  // useEffect(() => {init(seti)}, [])

  if (steps.length === 0) {
    return null
  }

  const goBack = () => seti(i => Math.max(0, i - 1))
  const goNext = () => seti(i => Math.min(steps.length-1, i + 1))

  return (
    <>
      {createElement(steps[i], {}, [])}
      {shownav && (
        <div>
          <button className="btn primary outline" onClick={_ => onBack(goBack)}>{buttonText.back}</button>
          <button className="btn primary" onClick={_ => onNext(goNext)}>{buttonText.next}</button>
        </div>
      )}
    </>
  )
}

function PrivacyScreen() {
  useEffect(() => {
    console.log('privacy screen loaded')
  }, [])

  return (
    <div>privacy screen</div>
  )
}

function ConfirmNumbersScreen() {
  useEffect(() => {
    console.log('confirm numbers screen loaded')
  }, [])
  return (
    <div>do you know your numbers screen</div>
  )
}

function Progress({cur, steps}) {
  const children = steps.map((s, i) => {
    let state = ''
    if (i < cur) {
      state = 'completed'
    }
    if (i === cur) {
      state = 'current'
    }
    if (i > cur) {
      state = 'incomplete'
    }
    return <Step key={s} title={s} state={state} />
  })

  const numSpaces = steps.length - 1

  const progress = Math.min(((cur / numSpaces)*100).toFixed(2), 100)
  
  return (
    <div className="progress">
      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", position: "relative"}}>
        <div style={{position: "absolute", display: "flex", justifyContent: "space-between", width: "100%"}}>
          {children}
        </div>
        <div style={{height: "2px", background: "lightgrey"}}>
          <div className="inner-bar" style={{height: "100%", width: `${progress}%`}}></div>
        </div>
      </div>
    </div>
  )
}

function Step({title, state}) {
  return (
    <div className={`progress-step ${state}`}>
      <span>
        {title}
      </span>
    </div>
  )
}

function Component({object}) {
  console.log('hello')
  return (
    <div>name: {object.name}</div>
  )
}

function Carousel({children=[], cur=0, back=() => {}, next=() => {}}) {
  return (
    <div>
      {
        children.map((c, i) => (
          <div style={{display: i === cur ? undefined : "none"}}>{c}</div>
        ))
      }
      <button onClick={back}>back</button>
      <button onClick={next}>next</button>
    </div>
  )
}

function withShow(Wrapped) {
  return ({show=false, ...rest}) => {
    return (
      <div style={{display: show ? undefined : "none"}}>
        <Wrapped {...rest} />
      </div>
    )
  }
}

function A({a}) {
  return <div>{a}</div>
}

function B({b}) {
  return <div>{b}</div>
}

function C({c}) {
  return <div>{c}</div>
}

// function CardList({children}) {
//   const style = {
//     backgroundColor: "#FFFFFF",
//     border: "1px solid var(--black-16)",
//     borderRadius: "8px",
//     padding: "16px"
//   }
//   return (
//     <div style={style}>
//       {children}
//     </div>
//   )
// }
//
// function ProductSelectList({onSelect}) {
//   const products = [
//     {
//       value: "2month",
//       title: "Zena - 2 month supply",
//       price: 19.99
//     },
//     {
//       value: "4month",
//       title: "Zena - 4 month supply",
//       price: 36.99
//     }
//   ]
//
//   const [value, setValue] = useState("2month")
//
//   const handleEvent = e => {
//     if (e.type === "UPDATED_ANSWER") {
//       setValue(e.newValue)
//     }
//   }
//
//   useEffect(() => onSelect(value), [value])
//
//   const textStyle = {
//     fontSize: "18px",
//     lineHeight: "26px",
//     fontWeight: "bold"
//   }
//
//   return (
//     <div>
//       {
//         <CardList>
//           <div style={{display: "flex", flexDirection: "column", gap: "32px"}}>
//             {
//               products.map(p => (
//                 <label>
//                   <div style={{display: "flex", gap: "32px", alignItems: "center"}}>
//                     <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", flex: "auto"}}>
//                       <h2 style={{...textStyle, margin: 0}}>{p.title}</h2>
//                       <span style={textStyle}>${p.price}</span>
//                     </div>
//                     <Radio
//                       name="product"
//                       value={p.value}
//                       checked={value === p.value}
//                       onChange={setValue}
//                       labelled={true}/>
//                   </div>
//                 </label>
//               ))
//             }
//           </div>
//         </CardList>
//       }
//     </div>
//   )
// }
