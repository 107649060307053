import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { client } from './client'

const AuditContext = React.createContext()

export function useAudit(e) {
  const { assessmentId } = useParams()
  let onEvent = useContext(AuditContext)

  if (!onEvent) {
    onEvent = e => {
      return client.recordEvent({assessmentid: assessmentId, eventtype: e.type, data: JSON.stringify(e)})
        .catch(err => console.error(err))
    }
  }

  useEffect(() => {
    if (e) {
      onEvent(e)
    }  
  }, [])

  return onEvent
}


export default AuditContext
