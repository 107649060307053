import { useContext } from 'react'

import { registerComponent, createComponent, ComponentContext } from './AssessmentComponents.js'

function Checkmark({size}) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M6.735 12.322a1 1 0 0 0-1.47 1.356l3.612 3.919c.537.526 1.337.526 1.834.03l.364-.359a2335.638 2335.638 0 0 0 3.939-3.883l.04-.04a492.598 492.598 0 0 0 3.658-3.643 1 1 0 0 0-1.424-1.404 518.42 518.42 0 0 1-3.64 3.625l-.04.04a2049.114 2049.114 0 0 1-3.775 3.722l-3.098-3.363z" fill="currentColor"/></svg>
  )
}

function Smoking() {
  return (
    //<!-- License: GPL. Made by coreui: https://github.com/coreui/coreui-icons/ -->
    <svg width="24px" height="24px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path fill="var(--ci-primary-color, currentColor)" d="M16,240V360H360V240H16Zm312,88H48V272H328Z"/>
      <rect width="32" height="120" x="384" y="240" fill="var(--ci-primary-color, currentColor)"/>
      <rect width="32" height="120" x="440" y="240" fill="var(--ci-primary-color, currentColor)"/>
      <path fill="var(--ci-primary-color, currentColor)" d="M385.428,173.3a31.982,31.982,0,0,1,2.32-38.418,63.745,63.745,0,0,0,3.479-78.69L385.377,48H348.8l-1.82,1.3,18.207,25.49a31.807,31.807,0,0,1-1.736,39.265,64.1,64.1,0,0,0-4.649,76.993L364.77,200h38.46Z" />
      <path fill="var(--ci-primary-color, currentColor)" d="M457.428,173.3a31.982,31.982,0,0,1,2.32-38.418,63.745,63.745,0,0,0,3.479-78.69L457.377,48H420.8l-1.82,1.3,18.207,25.49a31.807,31.807,0,0,1-1.736,39.265,64.1,64.1,0,0,0-4.649,76.993L436.77,200h38.46Z"/>
    </svg>
  )
}

const theme = {
  primary: "rgb(83,66,153)",
  secondary: "rgb(186,186,186)",
}

const hidden = {
  position: 'absolute',
  opacity: 0,
  width: 0,
  height: 0,
}

const radioStyle = {
  borderRadius: "50%",
  WebkitBorderRadius: "50%",
  boxShadow: `0 0 0 1px ${theme.secondary}`,
  overflow: "hidden",
  outline: "none",
  // https://github.com/Fyrd/caniuse/issues/6099
  //outline: "1px solid lightgray",
  width: 32,
  height: 32,
  color: "lightgray",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
}

const selectedRadioStyle = {
  ...radioStyle,
  backgroundColor: theme.primary,
}

const subtextStyle = {
  fontSize: "80%",
  color: theme.secondary,
}

const lastInnerRowStyle = {
  display: 'flex',
  flexDirection: 'row',
  padding: "20px 20px 20px 0",
  flexGrow: 1,
}

const innerRowStyle = {
  ...lastInnerRowStyle,
  borderBottom: "1px solid lightgray",
}

function Radio({label, value, subtext, selected, onChange, isLast}) {
  return (
    <div style={isLast ? lastInnerRowStyle : innerRowStyle} onClick={onChange}>
      <div style={{ flexGrow: 1, paddingRight: "20px" }}>
        <div>
          <label htmlFor={value}> { label } </label>
        </div>
        <div style={ subtextStyle }>
          { subtext }
        </div>
      </div>

      <div style={selected ? selectedRadioStyle : radioStyle}>
        <input id={value} style={hidden} type="radio" name={label} value={value}
          onChange={onChange} selected={selected} />

        { selected && <Checkmark size={20}/> }
      </div>
    </div>
  )
}

function RowItem({children}) {
  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
  }

  return (
    <div style={rowStyle}>
      { children }
    </div>
  )
}

function Icon({icon}) {
  return (
    <div style={{ padding: "20px" }}>
      <Smoking/>
    </div>
  )
}

export function CustomRadioGroup({id, question, options, value, onEvent, onSubmit, format}) {
  //const ctx = useContext(ComponentContext)
  const selected = value

  const style = {
    display: 'flex',
    flexDirection: "column",
    border: '1px solid lightgray',
    borderRadius: '5px'
  }

  return (
    <div style={style}>
      { options.map(({label, subtext, value}, i) => {
          const isSelected = selected == value
          const isLast = i == options.length - 1
          const handleSelect = () => onEvent({componentId: id, type: 'UPDATED_ANSWER', newValue: value})

          const props = {
            //...ctx,
            label,
            value,
            subtext,
            selected: isSelected,
            isLast,
            onChange: handleSelect,
            key: value,
          }
          return createComponent(format, props)
          //return (
          //  <Row>
          //    <Icon icon={"smoking"}/>

          //    <Radio label={label} value={value} subtext={subtext} selected={isSelected}
          //      onChange={handleSelect} isLast={isLast}/>
          //  </Row>
          //)
      })}

    </div>
  )
}

export default function RadioGroup({id, question, options, value, onEvent}) {
  const selected = value

  const style = {
    display: 'flex',
    flexDirection: "column",
    border: '1px solid lightgray',
    borderRadius: '5px'
  }

  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
  }

  const lastInnerRowStyle = {
    display: 'flex',
    flexDirection: 'row',
    padding: "20px 20px 20px 0",
    flexGrow: 1,
  }

  const innerRowStyle = {
    ...lastInnerRowStyle,
    borderBottom: "1px solid lightgray",
  }



  return (
    <div style={style}>
      { options.map(({label, subtext, value}, i) => {
          const isSelected = selected == value
          const isLast = i == options.length - 1
          const handleSelect = () => onEvent({componentId: id, type: 'UPDATED_ANSWER', newValue: value})

          return (
            <div key={value} style={rowStyle}>
              <div style={{ padding: "20px" }}>
                <Smoking/>
              </div>

              <div style={isLast ? lastInnerRowStyle : innerRowStyle} onClick={handleSelect}>
                <div style={{ flexGrow: 1, paddingRight: "20px" }}>
                  <div>
                    <label htmlFor={value}> { label } </label>
                  </div>
                  <div style={ subtextStyle }>
                    { subtext }
                  </div>
                </div>

                <div style={isSelected ? selectedRadioStyle : radioStyle}>
                  <input id={value} style={hidden} type="radio" name={label} value={value}
                    onChange={handleSelect} selected={selected == value} />

                  { isSelected && <Checkmark size={20}/> }
                </div>
              </div>
            </div>
          )
      })}

    </div>
  )
}

registerComponent('RadioGroup', RadioGroup)
registerComponent('CustomRadioGroup', CustomRadioGroup)
registerComponent('Radio', Radio)
registerComponent('Icon', Icon)
registerComponent('RowItem', RowItem)
